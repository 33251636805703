import Badge from "@/admin/experiences/badges/Badge"
import { ModuleDropdownItem_ContentFragment$key } from "@/content-usage/modules/__generated__/ModuleDropdownItem_ContentFragment.graphql"
import Relay from "@/relay/relayUtils"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  moduleKey: ModuleDropdownItem_ContentFragment$key
  className?: string
}

function ModuleDropdownItem(props: Props) {
  const { testid, moduleKey, className } = props

  const module = useFragment<ModuleDropdownItem_ContentFragment$key>(
    graphql`
      fragment ModuleDropdownItem_ContentFragment on Content {
        name
        product {
          name
          badge {
            ...BadgeFragment
          }
        }
        usages {
          edges {
            node {
              id
              entity
              collection {
                app {
                  customAppTitle
                  badge {
                    ...BadgeFragment
                  }
                }
              }
            }
          }
        }
      }
    `,
    moduleKey
  )

  const usage = Relay.connectionToArray(module?.usages)[0]

  // if module is in a curriculum
  if (usage.entity === "curriculum") {
    return (
      <DiscoDropdownItem
        className={className}
        testid={testid}
        icon={
          module.product?.badge ? (
            <Badge badgeKey={module.product.badge} size={24} />
          ) : null
        }
        title={module.name}
        subtitle={module.product?.name}
      />
    )
  }

  // otherwise module is in a collection
  const app = usage.collection?.app

  return (
    <DiscoDropdownItem
      className={className}
      testid={testid}
      icon={app?.badge ? <Badge badgeKey={app.badge} size={24} /> : null}
      title={module.name}
      subtitle={app?.customAppTitle}
    />
  )
}

export default ModuleDropdownItem
