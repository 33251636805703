import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSection, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface QuizSubmissionIncompleteTileProps extends TestIDProps {
  title?: string
}

function QuizSubmissionIncompleteTile({
  testid = "QuizSubmissionIncompleteTile",
  title = "Incomplete Submission",
}: QuizSubmissionIncompleteTileProps) {
  const classes = useStyles()

  return (
    <DiscoSection
      testid={testid}
      padding={0}
      radius={"default"}
      className={classes.incompleteSubmissionTile}
    >
      <DiscoText
        testid={`${testid}.text`}
        variant={"body-sm-600"}
        color={"text.secondary"}
      >
        {title}
      </DiscoText>
    </DiscoSection>
  )
}
const useStyles = makeUseStyles((theme) => ({
  incompleteSubmissionTile: {
    width: "100%",
    backgroundColor: theme.palette.groovy.neutral[100],
    display: "grid",
    placeItems: "center",
    height: "40px",
  },
}))
export default QuizSubmissionIncompleteTile
