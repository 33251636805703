import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import CloneContentModuleButton from "@/content-usage/buttons/CloneContentModuleButton"
import DeleteContentUsageButton from "@/content-usage/buttons/DeleteContentUsageButton"
import ContentUsageAssociateEventsButton from "@/content-usage/buttons/events/ContentUsageAssociateEventsButton"
import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import CreateContentModuleItemButton from "@/content-usage/modules/actions/CreateContentModuleItemButton"
import UpdateContentModuleButton from "@/content-usage/modules/actions/UpdateContentModuleButton"
import { ContentModuleAdminDropdownFragment$key } from "@/content-usage/modules/actions/__generated__/ContentModuleAdminDropdownFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentModuleAdminDropdownProps extends TestIDProps {
  contentUsageKey: ContentModuleAdminDropdownFragment$key
  showAddItems?: boolean
  className?: string
}

function ContentModuleAdminDropdown({
  testid = "ContentModuleAdminDropdown",
  contentUsageKey,
  showAddItems,
  className,
}: ContentModuleAdminDropdownProps) {
  const activeProduct = useActiveProduct()!
  const { connectedApps } = useConnectedProductApps(activeProduct)
  const activeOrganization = useActiveOrganization()!

  const contentModuleUsage = useFragment<ContentModuleAdminDropdownFragment$key>(
    graphql`
      fragment ContentModuleAdminDropdownFragment on ContentUsage {
        id
        entity
        ...CreateContentModuleItemButtonFragment
        ...UpdateContentModuleButtonFragment
        ...DeleteContentUsageButtonFragment
        ...ContentUsageAssociateEventsButtonFragment
      }
    `,
    contentUsageKey
  )

  const isCurriculumModule = contentModuleUsage.entity === "curriculum"
  const contentModuleLabel = ContentModuleUtils.getContentModuleLabel(
    contentModuleUsage.entity
  )

  return (
    <DiscoMoreActionsDropdown
      testid={testid}
      moreActionsButtonClassName={className}
      rotateIcon
    >
      {showAddItems && (
        <CreateContentModuleItemButton
          contentUsageKey={contentModuleUsage}
          testid={testid}
        >
          {(btnProps) => (
            <DiscoDropdownItem
              {...btnProps}
              icon={"add"}
              title={"Add Content"}
              testid={`${testid}.add-content-button`}
            />
          )}
        </CreateContentModuleItemButton>
      )}
      <UpdateContentModuleButton contentUsageKey={contentModuleUsage}>
        {(btnProps) => (
          <DiscoDropdownItem
            {...btnProps}
            icon={"pencil"}
            title={`Edit ${contentModuleLabel}`}
            testid={`${testid}.edit-module-button`}
          />
        )}
      </UpdateContentModuleButton>
      {(connectedApps.has("events") || activeOrganization.isEventsEnabled) &&
        isCurriculumModule && (
          <ContentUsageAssociateEventsButton contentUsageKey={contentModuleUsage}>
            {(btnProps) => (
              <DiscoDropdownItem
                {...btnProps}
                icon={"link"}
                title={"Associate Events"}
                testid={`${testid}.associate-events-button`}
              />
            )}
          </ContentUsageAssociateEventsButton>
        )}
      <CloneContentModuleButton contentModuleUsageId={contentModuleUsage.id}>
        {(btnProps) => (
          <DiscoDropdownItem
            {...btnProps}
            icon={"copy"}
            title={"Clone"}
            testid={`${testid}.clone-module-button`}
          />
        )}
      </CloneContentModuleButton>
      <DeleteContentUsageButton contentUsageKey={contentModuleUsage}>
        {(btnProps) => (
          <DiscoDropdownItem
            {...btnProps}
            icon={"trash"}
            title={`Delete ${contentModuleLabel}`}
            testid={`${testid}.delete-module-button`}
          />
        )}
      </DeleteContentUsageButton>
    </DiscoMoreActionsDropdown>
  )
}

export default ContentModuleAdminDropdown
