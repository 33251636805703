import QuizDrawerQuestionsFooter from "@/content-usage/drawer/quizzes/QuizDrawerQuestionsFooter"
import { TestIDProps } from "@utils/typeUtils"
import { forwardRef } from "react"

interface QuizSubmissionEditorFooterProps extends TestIDProps {
  children?: React.ReactNode
}

export default forwardRef<HTMLDivElement, QuizSubmissionEditorFooterProps>(
  function QuizSubmissionEditorFooter(
    { testid = "QuizSubmissionEditorFooter", children },
    ref
  ) {
    return (
      <QuizDrawerQuestionsFooter ref={ref} testid={testid}>
        {children}
      </QuizDrawerQuestionsFooter>
    )
  }
)
