/**
 * @generated SignedSource<<79f97130cd133d2931a70856d9fc97a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentModuleEvents_ContentUsageFragment$data = {
  readonly occurrences: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"ContentModuleEventsListItem_OccurrenceFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "ContentModuleEvents_ContentUsageFragment";
};
export type ContentModuleEvents_ContentUsageFragment$key = {
  readonly " $data"?: ContentModuleEvents_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleEvents_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentModuleEvents_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OccurrenceNodeConnection",
      "kind": "LinkedField",
      "name": "occurrences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Occurrence",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ContentModuleEventsListItem_OccurrenceFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "a1fa227799f52cfc8db312c4cd0a727e";

export default node;
