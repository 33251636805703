import { CloneContentModuleFormState } from "@/content-usage/buttons/CloneContentModuleButton"
import CloneContentDueDateItem from "@/content-usage/forms/CloneContentDueDateItem"
import { CloneContentModuleContentDueDatesFormFieldsFragment$key } from "@/content-usage/forms/__generated__/CloneContentModuleContentDueDatesFormFieldsFragment.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoDatetimePicker from "@disco-ui/date/DiscoDatetimePicker"
import { getDifferenceInDaysBetweenDates } from "@utils/time/timeUtils"
import { addDays } from "date-fns"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  contentModuleUsageKey: CloneContentModuleContentDueDatesFormFieldsFragment$key
  form: FormStore<CloneContentModuleFormState>
}

function CloneContentModuleContentDueDatesFormFields({
  contentModuleUsageKey,
  form,
}: Props) {
  const curriculumLabel = useLabel("curriculum")
  const classes = useStyles()

  const contentModuleUsage =
    useFragment<CloneContentModuleContentDueDatesFormFieldsFragment$key>(
      graphql`
        fragment CloneContentModuleContentDueDatesFormFieldsFragment on ContentUsage {
          id
          product {
            startDate
          }
          content {
            # the content record for the module
            id
            contentUsages: children {
              # For the content module -> get all content_usages with a due date
              edges {
                node {
                  id
                  dueAt
                  content {
                    name
                  }
                }
              }
            }
          }
        }
      `,
      contentModuleUsageKey
    )

  const contentUsages = Relay.connectionToArray(contentModuleUsage.content?.contentUsages)
  const contentUsagesWithDueDate = contentUsages.filter((cu) => Boolean(cu.dueAt))

  useEffect(() => {
    // form.state.assignments is only initialized once this component gets rendered
    calculateDefaultDueDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.releasedAt])

  return (
    <>
      <DiscoText
        testid={"CloneContentModuleContentDueDatesFormFields.description"}
        color={"text.secondary"}
        marginBottom={2.5}
      >{`The ${curriculumLabel.singular} contains ${
        contentUsagesWithDueDate.length
      } ${pluralize(
        "item",
        contentUsagesWithDueDate.length
      )} with a set due date. You can change the due date now or update them later in the settings.`}</DiscoText>

      <div className={classes.assignmentsContainer}>
        {contentUsagesWithDueDate.map((contentUsage, i) => {
          const destinationContentUsage = form.state.contentUsages?.find(
            (cu) => cu.sourceId === contentUsage.id
          )
          if (!destinationContentUsage) return null

          return (
            <DiscoDatetimePicker
              key={contentUsage.id}
              testid={"CloneContentModuleContentDueDatesFormFields.due-at"}
              onSave={(newDate) => handleSave(newDate, { id: contentUsage.id })}
              minDate={new Date()}
              initialDate={new Date(destinationContentUsage.dueAt)}
              timeLabel={"Due Time"}
            >
              {({ onClick }) => (
                <CloneContentDueDateItem
                  testid={`CloneContentModuleContentDueDatesFormFields.due-at.index-${i}`}
                  onCalendarClick={onClick as VoidFunction}
                  title={contentUsage.content.name || ""}
                  dueDate={new Date(destinationContentUsage.dueAt)}
                />
              )}
            </DiscoDatetimePicker>
          )
        })}
      </div>
    </>
  )

  function handleSave(newDate: Date, contentUsage: { id: GlobalID }) {
    const currentContentUsage = form.state.contentUsages?.find(
      (ci) => ci.sourceId === contentUsage.id
    )
    if (!currentContentUsage) return
    currentContentUsage.dueAt = newDate.toISOString() // set the new due date for the content usage
  }

  function calculateDefaultDueDates() {
    form.state.contentUsages!.replace(
      contentUsagesWithDueDate.map(({ id, dueAt }) => {
        // The time delta between the due date relative to the start date of the content module it was in
        const sourceRelativeStartDate =
          form.initialState.releasedAt || contentModuleUsage.product?.startDate

        // If there is no start date to offset the new default due date to, default to 7 days
        const diff = sourceRelativeStartDate
          ? getDifferenceInDaysBetweenDates(dueAt!, sourceRelativeStartDate)
          : 7

        const destinationRelativeStartDate =
          form.state.releasedAt || form.state.destinationProduct?.startDate

        return {
          sourceId: id,
          dueAt: addDays(
            destinationRelativeStartDate
              ? new Date(destinationRelativeStartDate)
              : new Date(),
            diff
          ).toISOString(),
        }
      })
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  assignmentsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
}))

export default observer(CloneContentModuleContentDueDatesFormFields)
