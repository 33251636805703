/**
 * @generated SignedSource<<f69db062c549a0a4f004532914c57b9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentUsageSubmissionMethod = "submission" | "mark_as_complete" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentUsageAssignmentSubmitBannerFragment$data = {
  readonly id: string;
  readonly submissionMethod: ContentUsageSubmissionMethod | null;
  readonly viewerSubmission: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_useSubmissionDeadlineFragment" | "SubmitAssignmentFormFragment">;
  readonly " $fragmentType": "ContentUsageAssignmentSubmitBannerFragment";
};
export type ContentUsageAssignmentSubmitBannerFragment$key = {
  readonly " $data"?: ContentUsageAssignmentSubmitBannerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentUsageAssignmentSubmitBannerFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentUsageAssignmentSubmitBannerFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentSubmission",
      "kind": "LinkedField",
      "name": "viewerSubmission",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageUtils_useSubmissionDeadlineFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmitAssignmentFormFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "23d37e241e7fddc1a71e70d97a982c3a";

export default node;
