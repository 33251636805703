import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { QuizRevision, QuizSubmission } from "@/web-form/utils/webFormQueryUtils"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { useEffect, useState } from "react"

export type QuizSubmissionResultFilter =
  | "correct"
  | "incorrect"
  | "all"
  | "complete"
  | "incomplete"

export namespace QuizSubmissionUtils {
  export type Mode = "submitter" | "reviewer"

  /** @param `args.answerKeyMap` - provide the answerKey map for cases where an answer doesn't exist and we need to show which question options are correct */
  export function useAnswersListState({
    revision,
    submission,
    mode = "submitter",
    answerKeyMap,
  }: {
    revision: QuizRevision
    submission: QuizSubmission
    mode?: Mode
    answerKeyMap?: Map<string, readonly string[]>
  }) {
    const [filter, setFilter] = useState<QuizSubmissionResultFilter>("all")
    const [currentQuestionId, setCurrentQuestionId] = useState<GlobalID | null>(null)

    const rawAnswers = Relay.connectionToArray(submission?.answers)

    const answers = Relay.connectionToArray(revision?.questions)
      .map((question) => {
        const answer = rawAnswers.find((a) => question.id === a.webFormQuestionId)
        if (mode === "submitter") {
          if (!answer) throw new Error("Answer not found")
          return { ...answer, question }
        }

        const correctIds = [...(answerKeyMap?.get(question.id) ?? [])]
        const selectedOptions = [...(answer?.selectedOptions ?? [])]
        const { added } = ArrayUtils.diff(correctIds, selectedOptions)
        return {
          ...(answer
            ? {
                ...answer,
                // if a submission is complete, answers will be evaluated. Otherwise, we need to calculate the result
                isCorrect:
                  answer.isCorrect ?? submission?.hasAnswerKey ? !added.length : null,
                result:
                  answer.result ?? submission?.hasAnswerKey
                    ? {
                        correctIds,
                        incorrectSelections: added,
                      }
                    : null,
              }
            : {
                id: `placeholder-answer-${question.id}`,
                webFormQuestionId: question.id,
                body: null,
                isSkipped: true,
                isCorrect: null,
                selectedOptions: [],
                result: {
                  correctIds,
                  incorrectSelections: [],
                },
              }),
          question,
        }
      })
      .sort((a, b) => a.question.ordering - b.question.ordering)

    const filteredAnswers = answers.filter((a) => {
      if (filter === "correct" || filter === "incorrect")
        return a.isCorrect === (filter === "correct")
      if (filter === "complete" || filter === "incomplete")
        return a.isSkipped === (filter === "incomplete")
      return filter === "all"
    })

    useEffect(() => {
      setCurrentQuestionId(null)
      setFilter("all")
    }, [submission?.id])

    return {
      filteredAnswers,
      currentQuestionId,
      setCurrentQuestionId,
      filter,
      setFilter,
    }
  }
}
