import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import ContentModuleCompletionModal from "@/content-usage/modules/components/ContentModuleCompletionModal"
import { GlobalID } from "@/relay/RelayTypes"
import { useState } from "react"

/** Fires the confetti cannon whenever the user completes a content module */
export default function ContentModuleCompletionConfetti() {
  const [completedModuleId, setCompletedModuleId] = useState<GlobalID | null>(null)
  ContentModuleUtils.useOnCurriculumModuleCompleted(setCompletedModuleId)

  if (!completedModuleId) return null
  return (
    <ContentModuleCompletionModal
      contentUsageId={completedModuleId}
      onClose={() => setCompletedModuleId(null)}
    />
  )
}
