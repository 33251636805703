import { InlineContentDrawerFormStore } from "@/content-usage/drawer/InlineContentDrawerTemplate"
import { UpdateContentDueDateNotificationModal_PreviewMutation } from "@/content-usage/drawer/settings/__generated__/UpdateContentDueDateNotificationModal_PreviewMutation.graphql"
import ContentUtils from "@/content/util/contentUtils"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoCheckbox,
  DiscoFormControl,
  DiscoInput,
  DiscoModal,
  DiscoText,
} from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { graphql } from "relay-runtime"

interface Props {
  form: InlineContentDrawerFormStore
  onSubmit: VoidFunction
  onClose: VoidFunction
  contentName?: string | null
}

function UpdateContentDueDateNotificationModal({
  form,
  onSubmit,
  onClose,
  contentName,
}: Props) {
  const [shouldSendEmail, setShouldSendEmail] = useState(true)

  const { authUser } = useAuthUser({ required: true })
  const activeProduct = useActiveProduct()!
  const membersLabel = useLabel("product_member")
  const classes = useStyles()

  // On creation name is in form state, on update it should be passed as a prop
  const name = contentName || form.state.content.name || ""

  const previewEmailForm =
    useFormStore<UpdateContentDueDateNotificationModal_PreviewMutation>(
      graphql`
        mutation UpdateContentDueDateNotificationModal_PreviewMutation(
          $input: PreviewCurriculumItemDueDateChangeEmailInput!
        ) {
          response: previewCurriculumItemDueDateChangeEmail(input: $input) {
            data
            errors {
              field
              message
            }
          }
        }
      `,
      {
        productId: activeProduct.id,
        subject:
          form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput?.emailContent
            ?.subject || JSON.stringify(EditorUtils.createParagraphs([""])),
        body:
          form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput?.emailContent
            ?.richBody || JSON.stringify(EditorUtils.createParagraphs([""])),
        name,
        oldDueDate: form.initialState.contentUsageInput?.dueAt,
        newDueDate: form.state.contentUsageInput?.dueAt,
      }
    )

  useEffect(() => {
    if (shouldSendEmail) {
      setDefaultEmailState()
    } else {
      form.state.contentUsageInput = {
        ...form.state.contentUsageInput,
        dueDateUpdatedEmailMessageInput: null,
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSendEmail])

  const contentLabels = ContentUtils.useContentLabels()
  const contentLabel = ContentUtils.useContentLabel({
    content: {
      type: form.state.contentType!,
      label:
        contentLabels.find((cl) => cl.id === form.state.content.contentLabelId)?.label ||
        "Content",
    },
    entity: form.state.contentUsageInput!.entity!,
  })

  return (
    <DiscoModal
      onClose={handleClose}
      testid={"UpdateContentDueDateNotificationModal"}
      isOpen
      title={`Notify ${membersLabel.plural} via Email`}
      subtitle={`Customize the email subject and body if you want to share more details with ${membersLabel.plural}.`}
      modalContentLabel={"Notify users that we are updating the due date."}
      width={"720px"}
      minHeight={"unset"}
      maxWidth={"90vw"}
      body={
        <div>
          <DiscoCheckbox
            onChange={handleToggleNotification}
            testid={
              "UpdateContentDueDateNotificationModal.send-notification-email-checkbox"
            }
            label={`Notify all ${membersLabel.plural} via email`}
            checked={shouldSendEmail}
            className={shouldSendEmail ? classes.notificationToggleCheckbox : undefined}
          />
          {shouldSendEmail && (
            <>
              <DiscoFormControl
                label={<DiscoText>{"Email Subject"}</DiscoText>}
                errorMessages={form.errorsByField.dueDateUpdatedEmailSubject}
              >
                <DiscoInput
                  data-testid={"UpdateContentDueDateNotificationModal.subject-input"}
                  value={
                    form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput
                      ?.emailContent?.subject
                  }
                  onChange={(e) => {
                    form.state.contentUsageInput!.dueDateUpdatedEmailMessageInput!.emailContent!.subject =
                      e.target.value
                  }}
                />
              </DiscoFormControl>
              <DiscoFormControl
                label={<DiscoText>{"Email Body"}</DiscoText>}
                errorMessages={form.errorsByField.dueDateUpdatedEmailRichEditorBody}
              >
                {form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput
                  ?.emailContent?.richBody && (
                  <DiscoEditor
                    defaultValue={
                      form.state.contentUsageInput.dueDateUpdatedEmailMessageInput
                        .emailContent.richBody
                    }
                    onChange={(v) => {
                      form.state.contentUsageInput!.dueDateUpdatedEmailMessageInput!.emailContent!.richBody =
                        v
                    }}
                    testid={
                      "UpdateContentDueDateNotificationModal.send-notification-email-rich-editor"
                    }
                    minHeight={200}
                    config={"email"}
                    showOutline
                  />
                )}
              </DiscoFormControl>
            </>
          )}
        </div>
      }
      classes={{ buttons: classes.modalButtonsContainer }}
      buttons={
        <div className={shouldSendEmail ? classes.buttonsContainer : undefined}>
          {shouldSendEmail && (
            <DiscoButton
              key={"preview"}
              testid={"UpdateContentDueDateNotificationModal.preview-button"}
              color={"transparent"}
              onClick={handleSendPreview}
              disabled={
                previewEmailForm.isSubmitting ||
                (shouldSendEmail &&
                  (!form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput
                    ?.emailContent?.subject ||
                    EditorUtils.isEmpty(
                      form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput
                        ?.emailContent?.richBody
                    )))
              }
              leftIcon={"send"}
            >
              {"Send Me Preview"}
            </DiscoButton>
          )}
          <DiscoButton
            key={"confirm"}
            testid={"UpdateContentDueDateNotificationModal.submit-button"}
            onClick={handleSubmit}
            className={shouldSendEmail ? undefined : classes.submitButton}
            disabled={
              form.isSubmitting ||
              (shouldSendEmail &&
                (!form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput
                  ?.emailContent?.subject ||
                  EditorUtils.isEmpty(
                    form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput
                      ?.emailContent?.richBody
                  )))
            }
            shouldDisplaySpinner={form.isSubmitting}
          >
            {shouldSendEmail ? `Notify ${membersLabel.plural}` : "Save"}
          </DiscoButton>
        </div>
      }
    />
  )

  async function handleSendPreview() {
    const { didSave } = await previewEmailForm.submit({
      productId: activeProduct.id,
      subject:
        form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput?.emailContent
          ?.subject || "",
      body:
        form.state.contentUsageInput?.dueDateUpdatedEmailMessageInput?.emailContent
          ?.richBody || "",
      name,
      oldDueDate: form.initialState.contentUsageInput?.dueAt,
      newDueDate: form.state.contentUsageInput?.dueAt,
    })
    if (!didSave) return

    displaySuccessToast({
      message: `Preview email sent to ${authUser.email}`,
      testid: "UpdateContentDueDateNotificationModal.preview-button.success",
    })
  }

  function setDefaultEmailState() {
    const isAddingDate =
      Boolean(!form.initialState.contentUsageInput?.dueAt) &&
      Boolean(form.state.contentUsageInput?.dueAt)
    const isRemovingDate = Boolean(!form.state.contentUsageInput?.dueAt)

    if (isAddingDate) {
      const subject = `${contentLabel} Due Date - ${name}`

      form.state.contentUsageInput = {
        ...form.state.contentUsageInput,
        dueDateUpdatedEmailMessageInput: {
          name: subject,
          emailContent: {
            send: true,
            subject,
            richBody: JSON.stringify(
              EditorUtils.createParagraphs([
                `A due date has been added to the ${contentLabel} ${name} in ${activeProduct.name}. To view the ${contentLabel}, click the button below:`,
              ])
            ),
          },
        },
      }
    } else if (isRemovingDate) {
      const subject = `Due Date Removed - ${name}`

      form.state.contentUsageInput = {
        ...form.state.contentUsageInput,
        dueDateUpdatedEmailMessageInput: {
          name: subject,
          emailContent: {
            send: true,
            subject,
            richBody: JSON.stringify(
              EditorUtils.createParagraphs([
                `The due date for the ${contentLabel} ${name} in ${activeProduct.name} has been removed. To view the ${contentLabel}, click the button below:`,
              ])
            ),
          },
        },
      }
    } else {
      const subject = `Due Date Changed - ${name}`

      form.state.contentUsageInput = {
        ...form.state.contentUsageInput,
        dueDateUpdatedEmailMessageInput: {
          name: subject,
          emailContent: {
            send: true,
            subject,
            richBody: JSON.stringify(
              EditorUtils.createParagraphs([
                `The due date for the ${contentLabel} ${name} in ${activeProduct.name} has been changed. To view the ${contentLabel}, click the button below:`,
              ])
            ),
          },
        },
      }
    }
  }

  function handleToggleNotification(checked: boolean) {
    setShouldSendEmail(checked)
  }

  function handleSubmit() {
    onSubmit()
    handleClose()
  }

  function handleClose() {
    // Clear the subject and body form state
    form.state.contentUsageInput = {
      ...form.state.contentUsageInput,
      dueDateUpdatedEmailMessageInput: null,
    }
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  modalButtonsContainer: {
    marginLeft: "unset",
    width: "100%",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  submitButton: {
    marginLeft: "auto",
  },
  notificationToggleCheckbox: {
    marginBottom: theme.spacing(4),
  },
}))

export default observer(UpdateContentDueDateNotificationModal)
