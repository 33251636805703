import QuizDrawerQuestionsHeader from "@/content-usage/drawer/quizzes/QuizDrawerQuestionsHeader"
import { useDrawerContext } from "@/core/context/DrawerContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoIcon } from "@disco-ui"
import DiscoDrawerHeaderProgressDivider from "@disco-ui/drawer/DiscoDrawerHeaderProgressDivider"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface QuizSubmissionEditorHeaderProps extends TestIDProps {
  title: string
  overlineTitle?: string
  onClose: VoidFunction
  onBack: VoidFunction
  totalQuestions?: number
  activeAnswerIndex?: number
  shouldDisplayBackSpinner?: boolean
  showResultSummaryButton: boolean
}

function QuizSubmissionEditorHeader({
  testid,
  title,
  overlineTitle = "Quiz",
  onClose,
  onBack,
  totalQuestions,
  activeAnswerIndex,
  shouldDisplayBackSpinner,
  showResultSummaryButton,
}: QuizSubmissionEditorHeaderProps) {
  const shouldShowQuestionProgress = Boolean(
    totalQuestions !== undefined && activeAnswerIndex !== undefined
  )
  const { openDrawerSidebar } = useDrawerContext()
  const isMobile = useIsMobile()
  const classes = useStyles()
  return (
    <QuizDrawerQuestionsHeader
      testid={testid}
      title={title}
      overlineTitle={overlineTitle}
      onClose={onClose}
      onBack={onBack}
      customDivider={
        shouldShowQuestionProgress ? (
          <DiscoDrawerHeaderProgressDivider
            totalItems={totalQuestions!}
            activeItemIndex={activeAnswerIndex!}
          />
        ) : isMobile && showResultSummaryButton ? (
          <DiscoButton
            color={"transparent"}
            onClick={openDrawerSidebar}
            rightIcon={<DiscoIcon icon={"chevron"} className={classes.rightChevron} />}
            className={classes.resultsHeaderButton}
          >
            {"Result Summary"}
          </DiscoButton>
        ) : undefined
      }
      shouldDisplayBackSpinner={shouldDisplayBackSpinner}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  rightChevron: {
    transform: "rotate(90deg)",
  },
  resultsHeaderButton: {
    width: "100%",
    justifyContent: "flex-end",
    borderRadius: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: 0,
  },
}))

export default observer(QuizSubmissionEditorHeader)
