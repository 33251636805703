import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import { ContentModuleCompletionModalQuery } from "@/content-usage/modules/components/__generated__/ContentModuleCompletionModalQuery.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useConfettiCannon } from "@/core/context/ConfettiContext"
import { useLabels } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoButton, DiscoModal, DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { LocationDescriptorObject } from "history"
import { useEffect } from "react"
import { useLazyLoadQuery } from "react-relay"
import { generatePath } from "react-router"
import { graphql } from "relay-runtime"

// exporting this from PathwaySequenceDashboardBlock was creating a circular dependency... so we'll define here
export type ScrollToPathwayDashboardBlockParams = {
  certBlock?: string
  groupID?: string
}

interface Props extends TestIDProps {
  contentUsageId: GlobalID
  onClose(): void
}

/**
 * Fires the confetti cannon whenever the user completes a content module, product, or pathway
 * and directs them to the next step in their learning journey (next module content, pathway or product dashboard)
 */
function ContentModuleCompletionModal(props: Props) {
  const classes = useStyles()
  const { contentUsageId, onClose, testid = "ContentModuleCompletionModal" } = props
  const labels = useLabels()

  const { contentUsage } = useLazyLoadQuery<ContentModuleCompletionModalQuery>(
    graphql`
      query ContentModuleCompletionModalQuery(
        $id: ID!
        $orderParentPathwaysBy: OrderByInput
      ) {
        contentUsage: node(id: $id) {
          ... on ContentUsage {
            id
            viewerCompletion {
              createdAt
            }
            content {
              name
            }
            product {
              id
              name
              slug
              viewerMembership {
                completedAt
              }
              viewerParentPathwayProducts(orderBy: $orderParentPathwaysBy) {
                edges {
                  node {
                    id
                    name
                    slug
                    viewerMembership {
                      completedAt
                    }
                    childPathwayGroups {
                      edges {
                        node {
                          id
                          title
                          viewerMembership {
                            completedAt
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            curriculum {
              modules {
                edges {
                  node {
                    id
                    releasedAt
                    viewerCompletion {
                      createdAt
                    }
                    content {
                      children(first: 1) {
                        edges {
                          node {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: contentUsageId,
      // order parent pathways by most recent viewer registration, so we can show the most relevant pathway completion or next product
      orderParentPathwaysBy: { field: "pm.creation_datetime", direction: "DESC" },
    },
    // always fetch by network because we don't (and shouldn't) query back all these fields on ContentUsageUtils_ContentCompletionFragment, so the cache will be missing data
    { fetchPolicy: "network-only" }
  )

  const activeProduct = useActiveProduct()!

  const confettiCannon = useConfettiCannon()
  useEffect(() => {
    if (!contentUsage?.id) return
    confettiCannon()
  }, [confettiCannon, contentUsage?.id])

  if (!contentUsage) return null

  const modalContent = getContentByCompletionState()

  return (
    <DiscoModal
      isOpen
      onClose={onClose}
      testid={testid}
      modalContentLabel={"Completed module"}
      minHeight={"400px"}
      classes={{
        buttons: classes.buttonsContainer,
        closeButton: classes.closeButton,
      }}
      body={
        <Grid
          data-testid={`${testid}.${modalContent.completionKind}`}
          container
          direction={"column"}
          alignItems={"center"}
        >
          <DiscoText variant={"heading-xxl"}>{modalContent.emoji}</DiscoText>
          <DiscoText
            testid={`${testid}.title`}
            variant={"heading-sm"}
            align={"center"}
            marginTop={1}
          >
            {modalContent.title}
          </DiscoText>

          <DiscoText
            testid={`${testid}.subtitle`}
            className={classes.modalSubtitle}
            marginTop={1.5}
            align={"center"}
            variant={"body-md"}
          >
            {modalContent.description}
          </DiscoText>
        </Grid>
      }
      buttons={modalContent.buttons?.map((button, i) => (
        <DiscoButton
          key={button.label}
          // first button in array is primary action, second is secondary. We should not pass more than 2 buttons.
          color={i === 0 ? "primary" : "grey"}
          variant={i === 0 ? "contained" : "outlined"}
          onClick={onClose}
          to={button.to}
          data-testid={button.testid}
        >
          {button.label}
        </DiscoButton>
      ))}
    />
  )

  // evaluate completion state based on module, product, pathway grouy and pathway completion
  function getContentByCompletionState(): {
    completionKind:
      | "module"
      | "product"
      | "product-incomplete-pathway"
      | "pathway"
      | "pathway-group"
    title: string
    description: React.ReactNode
    emoji: string
    buttons: { label: string; to: string | LocationDescriptorObject; testid: string }[]
  } {
    // module completion time
    const completedAt = contentUsage?.viewerCompletion?.createdAt

    /**
     * Product Completion conditions
     */
    const completedProduct = // module completion triggered product completion
      contentUsage?.product?.viewerMembership?.completedAt === completedAt
        ? contentUsage?.product
        : null
    const didCompleteProduct = !!completedProduct

    /**
     * Pathway Completion conditions
     */
    const firstIncompletePathway = // the most recently registered incomplete pathway
      contentUsage?.product?.viewerParentPathwayProducts.edges?.find(
        ({ node }) => !node.viewerMembership?.completedAt
      )?.node
    const hasIncompletePathway = !!firstIncompletePathway

    const completedPathway = // module completion triggered pathway completion
      contentUsage?.product?.viewerParentPathwayProducts?.edges?.find(
        ({ node }) => node.viewerMembership?.completedAt === completedAt
      )?.node
    const showCompletedPathway = !!completedPathway

    if (showCompletedPathway)
      return {
        completionKind: "pathway",
        emoji: "🎉",
        title: `You've completed a ${labels.pathway.singular}!`,
        description: (
          <>
            {`Congratulations! You have completed the ${labels.pathway.singular} `}
            {quoteBold(completedPathway.name)}
            {`. Nicely done!`}
          </>
        ),
        buttons: [
          {
            label: `Go to ${labels.pathway.singular} Dashboard`,
            to: {
              pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                productSlug: completedPathway.slug,
              }),
              search: setSearchParams<ScrollToPathwayDashboardBlockParams>("", {
                certBlock: "1",
              }),
            },
            testid: `${testid}.pathway-dashboard-button`,
          },
        ],
      }

    /**
     * Pathway Group Completion conditions
     */
    const incompletePathwayChildGroups = Relay.connectionToArray(
      firstIncompletePathway?.childPathwayGroups
    )

    let lastCompletedGroupIdx = -1
    for (let i = incompletePathwayChildGroups.length - 1; i > -1; i--) {
      // don't check the group completion time here becuase we want to use the index to get the incomplete group as well
      if (incompletePathwayChildGroups[i].viewerMembership?.completedAt) {
        lastCompletedGroupIdx = i
        break
      }
    }

    const lastCompletedGroup =
      lastCompletedGroupIdx >= 0
        ? incompletePathwayChildGroups[lastCompletedGroupIdx]
        : null
    const currentPathwayGroup = incompletePathwayChildGroups[lastCompletedGroupIdx + 1]

    // if module completion triggered previous group completion, show next group unlocked
    const showPathwayGroupUnlocked =
      !!lastCompletedGroup &&
      lastCompletedGroup.viewerMembership?.completedAt === completedAt &&
      // first incomplete pathway & completed product should exist, but check so we don't have to assert below
      firstIncompletePathway &&
      completedProduct

    if (showPathwayGroupUnlocked)
      return {
        completionKind: "pathway-group",
        emoji: "🔓",
        title: `${labels.pathway.singular} Group unlocked!`,
        description: (
          <>
            {`You have completed the ${labels.experience.singular} `}
            {quoteBold(completedProduct.name)}
            {` and unlocked the ${labels.pathway.singular} Group `}
            {quoteBold(currentPathwayGroup.title)}
            {`. Head to the `}
            {quoteBold(firstIncompletePathway.name)}
            {` ${labels.pathway.singular} Dashboard to continue your learning progress.`}
          </>
        ),
        buttons: [
          {
            label: `Go to ${labels.pathway.singular} Dashboard`,
            to: {
              pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                productSlug: firstIncompletePathway.slug,
              }),
              search: setSearchParams<ScrollToPathwayDashboardBlockParams>("", {
                groupID: currentPathwayGroup.id,
              }),
            },
            testid: `${testid}.pathway-dashboard-button`,
          },
        ],
      }

    // only show the pathway content when we finish the product (not when a module completes)
    if (hasIncompletePathway && didCompleteProduct)
      return {
        completionKind: "product-incomplete-pathway",
        emoji: "🚀",
        title: `Congrats! You've completed a ${labels.experience.singular}!`,
        description: (
          <>
            {`You have completed the ${labels.experience.singular} `}
            {quoteBold(completedProduct.name)}
            {`. Head to the `}
            {quoteBold(firstIncompletePathway.name)}
            {` ${labels.pathway.singular} Dashboard to continue your learning progress.`}
          </>
        ),
        buttons: [
          {
            label: `Go to ${labels.pathway.singular} Dashboard`,
            to: {
              pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                productSlug: firstIncompletePathway.slug,
              }),
              search: setSearchParams<ScrollToPathwayDashboardBlockParams>("", {
                groupID: currentPathwayGroup.id,
              }),
            },
            testid: `${testid}.pathway-dashboard-button`,
          },
        ],
      }

    if (didCompleteProduct)
      return {
        completionKind: "product",
        emoji: "🎉",
        title: `Congrats! You've completed a ${labels.experience.singular}!`,
        description: (
          <>
            {`You have completed the ${labels.experience.singular} `}
            {quoteBold(completedProduct.name)}
            {`. Nicely done!`}
          </>
        ),
        buttons: [
          {
            label: `Go to ${labels.experience.singular} Dashboard`,
            to: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
              productSlug: completedProduct.slug,
            }),
            testid: `${testid}.product-dashboard-button`,
          },
        ],
      }

    /**
     * Module Completion conditions
     */
    const currentContentModules = Relay.connectionToArray(
      contentUsage?.curriculum?.modules
    ).filter((module) => ContentModuleUtils.isReleased(module))

    const currentModuleIndex = currentContentModules.findIndex(
      (module) => module.id === contentUsage?.id
    )

    const isLastModule = currentModuleIndex === currentContentModules.length - 1
    const nextModule = isLastModule ? null : currentContentModules[currentModuleIndex + 1]
    const nextContentItemId = nextModule?.content?.children?.edges?.[0]?.node.id
    const hasNextModule = !!nextModule && !!nextContentItemId

    // base case module completion
    return {
      completionKind: "module",
      emoji: "🚀",
      title: `Congrats! You've completed a Module!`,
      description: (
        <>
          {`You have completed the Module `}
          {quoteBold(contentUsage!.content!.name!)}
          {`. Nicely done, keep up the good work!`}
        </>
      ),
      buttons: [
        ...(hasNextModule
          ? [
              {
                label: "Next Module",
                to: {
                  // Could not use GlobalDrawerParams due to circular import issue
                  search: setSearchParams(location.search, {
                    u: Relay.fromGlobalId(nextContentItemId).id,
                    // setting a parameter to undefined removes it from url
                    drawerQuizTab: undefined,
                    drawerQuizRetry: undefined,
                  }),
                },
                testid: `${testid}.next-section-button`,
              },
            ]
          : []),
        {
          label: `Back to ${labels.experience.singular} Dashboard`,
          to: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
            productSlug: activeProduct.slug,
          }),
          testid: `${testid}.product-dashboard-button`,
        },
      ],
    }
  }

  function quoteBold(text: string) {
    return <DiscoText variant={"body-md-600"} component={"span"}>{`"${text}"`}</DiscoText>
  }
}

const useStyles = makeUseStyles((theme) => ({
  modalSubtitle: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
  buttonsContainer: {
    width: "100% !important",
    justifyContent: "center !important",
    /**
     * reverse the buttons container so we can make the first button in the array primary,
     * but show it as the button farthest right when more than one button rendered
     */
    flexDirection: "row-reverse",
  },
  closeButton: {
    alignSelf: "start",
  },
}))

export default Relay.withSkeleton({
  component: ContentModuleCompletionModal,
  skeleton: () => null,
})
