import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import ContentModulesDragDropProvider, {
  CONTENT_MODULE_DROPPABLE_TYPE,
} from "@/content-usage/ContentModulesDragDropProvider"
import ContentModuleListItem, {
  ContentModuleListItemSkeleton,
} from "@/content-usage/modules/list/ContentModuleListItem"
import { ContentModuleListFragment$key } from "@/content-usage/modules/list/__generated__/ContentModuleListFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { BeforeCapture, Draggable } from "react-beautiful-dnd"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentModuleListProps extends TestIDProps {
  contentUsageConnectionKey: ContentModuleListFragment$key
  entityId: GlobalID
  canReorder?: boolean
  showProgressIcon?: boolean
  hideEmptySections?: boolean
  filterContentLabelIds?: GlobalID[]
  alwaysStartCollapsed?: boolean
}

function ContentModuleList({
  testid = "ContentModuleList",
  contentUsageConnectionKey,
  entityId,
  canReorder,
  showProgressIcon,
  hideEmptySections,
  filterContentLabelIds,
  alwaysStartCollapsed,
}: ContentModuleListProps) {
  const moduleConnection = useFragment<ContentModuleListFragment$key>(
    graphql`
      fragment ContentModuleListFragment on ContentUsageNodeConnection
      @argumentDefinitions(contentLabelIds: { type: "[ID!]" }) {
        edges {
          node {
            id
            prerequisites {
              totalCount
            }
            viewerHasCompleted
            releasedAt
            ...ContentModuleListItemFragment @arguments(contentLabelIds: $contentLabelIds)
          }
        }
      }
    `,
    contentUsageConnectionKey
  )

  const modules = Relay.connectionToArray(moduleConnection)

  const mostRecentlyReleasedModule = getMostRecentlyReleasedModule()

  return (
    <ContentModulesDragDropProvider
      entityId={entityId}
      droppableType={CONTENT_MODULE_DROPPABLE_TYPE}
      checkPrerequisites={checkPrerequisites}
    >
      <>
        {modules.map((module, i) => (
          <Draggable
            key={module.id}
            isDragDisabled={!canReorder}
            draggableId={module.id}
            index={i}
          >
            {(draggableProvided) => (
              <div
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                ref={draggableProvided.innerRef}
              >
                <ContentModuleListItem
                  key={module.id}
                  testid={`${testid}.module-${i}`}
                  contentUsageKey={module}
                  moduleIndex={i}
                  canReorder={canReorder}
                  showProgressIcon={showProgressIcon}
                  hideIfEmpty={hideEmptySections}
                  isMostRecentlyReleased={module.id === mostRecentlyReleasedModule?.id}
                  filterContentLabelIds={filterContentLabelIds}
                  alwaysStartCollapsed={alwaysStartCollapsed}
                />
              </div>
            )}
          </Draggable>
        ))}
      </>
    </ContentModulesDragDropProvider>
  )

  function checkPrerequisites(before: BeforeCapture) {
    const module = modules.find((m) => m.id === before.draggableId)
    if (module) {
      return Boolean(module.prerequisites?.totalCount)
    }
    return false
  }

  function getMostRecentlyReleasedModule() {
    for (let i = modules.length - 1; i >= 0; i--) {
      if (ContentModuleUtils.isReleased(modules[i])) {
        return modules[i]
      }
    }
    return null
  }
}

export const ContentModuleListSkeleton: React.FC = () => {
  return (
    <div>
      <ContentModuleListItemSkeleton />
      <ContentModuleListItemSkeleton />
      <ContentModuleListItemSkeleton />
      <ContentModuleListItemSkeleton />
      <ContentModuleListItemSkeleton />
    </div>
  )
}

export default ContentModuleList
