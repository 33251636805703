import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import ContentModuleForm from "@/content-usage/modules/actions/ContentModuleForm"
import { ContentUsageEntity } from "@/content-usage/modules/actions/__generated__/ContentModuleForm_UpdateContentUsageMutation.graphql"
import AddIcon from "@/core/ui/iconsax/linear/add.svg"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

interface CreateContentModuleButtonProps extends TestIDProps {
  children: OverridableDiscoButtonChildren
  parentEntityId: GlobalID
  parentEntity: ContentUsageEntity
  showAddIcon?: boolean
  ordering?: number
  disabled?: boolean
}

function CreateContentModuleButton({
  children,
  parentEntityId,
  parentEntity,
  ordering,
  showAddIcon,
  disabled = false,
  testid = "CreateContentModuleButton",
}: CreateContentModuleButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const contentModuleLabel = ContentModuleUtils.getContentModuleLabel(parentEntity)
  const description = getDescription()

  return (
    <>
      <OverridableDiscoButton
        testid={testid}
        onClick={onOpen}
        leftIcon={showAddIcon ? <AddIcon /> : undefined}
        disabled={disabled}
      >
        {children}
      </OverridableDiscoButton>

      <DiscoModal
        isOpen={isOpen}
        onClose={onClose}
        title={`Add ${contentModuleLabel}`}
        subtitle={description}
        testid={`Create${contentModuleLabel}Modal`}
        modalContentLabel={`Create ${contentModuleLabel} Dialog`}
        minHeight={parentEntity === "collection" ? "unset" : undefined}
        width={parentEntity === "curriculum" ? "720px" : undefined}
        maxWidth={"90vw"}
        buttons
        body={
          <ContentModuleForm
            parentEntity={parentEntity}
            parentEntityId={parentEntityId}
            onSubmit={onClose}
            onCancel={onClose}
            ordering={ordering}
          />
        }
      />
    </>
  )

  function getDescription() {
    switch (parentEntity) {
      case "collection":
        return "Add a new folder to organize resources, pages and more."
    }
    return null
  }
}

export default observer(CreateContentModuleButton)
