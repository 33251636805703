/**
 * @generated SignedSource<<060d235643da59ebee47bf4653f7f982>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloneContentModuleContentDueDatesFormFieldsFragment$data = {
  readonly id: string;
  readonly product: {
    readonly startDate: string | null;
  } | null;
  readonly content: {
    readonly id: string;
    readonly contentUsages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly dueAt: string | null;
          readonly content: {
            readonly name: string | null;
          };
        };
      }>;
    } | null;
  };
  readonly " $fragmentType": "CloneContentModuleContentDueDatesFormFieldsFragment";
};
export type CloneContentModuleContentDueDatesFormFieldsFragment$key = {
  readonly " $data"?: CloneContentModuleContentDueDatesFormFieldsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CloneContentModuleContentDueDatesFormFieldsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CloneContentModuleContentDueDatesFormFieldsFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "contentUsages",
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "children",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dueAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Content",
                      "kind": "LinkedField",
                      "name": "content",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "41d2fc5a8b6587cd9e6160d22d471b61";

export default node;
