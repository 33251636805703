import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import { ContentModuleReleaseDateFragment$key } from "@/content-usage/modules/components/__generated__/ContentModuleReleaseDateFragment.graphql"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import useUserTimezone from "@/user/util/useUserTimezone"
import { DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentModuleReleaseDateProps extends TestIDProps {
  contentUsageKey: ContentModuleReleaseDateFragment$key
  copyVariant?: "ago" | "on"
  dateFormat?: string
}

function ContentModuleReleaseDate({
  testid,
  contentUsageKey,
  copyVariant = "ago",
  dateFormat,
}: ContentModuleReleaseDateProps) {
  const classes = useStyles()
  const timeZone = useUserTimezone()

  const contentUsage = useFragment<ContentModuleReleaseDateFragment$key>(
    graphql`
      fragment ContentModuleReleaseDateFragment on ContentUsage {
        id
        releasedAt
      }
    `,
    contentUsageKey
  )

  const releasedAtMessage = ContentModuleUtils.useFormattedReleaseDate({
    releasedAt: contentUsage.releasedAt,
    timeZone,
    copy: {
      variant: copyVariant,
      dateFormat,
    },
  })

  if (!releasedAtMessage) return null

  return (
    <DiscoText className={classes.releaseDateText} testid={testid}>
      {releasedAtMessage}
    </DiscoText>
  )
}

const useStyles = makeUseStyles((theme) => ({
  releaseDateText: {
    fontSize: theme.typography["body-sm"].fontSize,
    color: theme.palette.text.secondary,

    [theme.breakpoints.down("xs")]: {
      paddingLeft: undefined,
    },
  },
}))

export default ContentModuleReleaseDate
