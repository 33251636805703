import ContentModuleEventsListItem, {
  ContentModuleEventsListItemProps,
} from "@/content-usage/modules/list/ContentModuleEventsListItem"
import { ContentModuleEvents_ContentUsageFragment$key } from "@/content-usage/modules/list/__generated__/ContentModuleEvents_ContentUsageFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

type Props = TestIDProps & {
  contentUsageKey: ContentModuleEvents_ContentUsageFragment$key
} & Pick<ContentModuleEventsListItemProps, "variant">

export default function ContentModuleEvents(props: Props) {
  const { contentUsageKey, testid = "ContentModuleEvents", variant } = props
  const classes = useStyles()

  const contentUsage = useFragment<ContentModuleEvents_ContentUsageFragment$key>(
    graphql`
      fragment ContentModuleEvents_ContentUsageFragment on ContentUsage {
        occurrences {
          totalCount
          edges {
            node {
              id
              ...ContentModuleEventsListItem_OccurrenceFragment
            }
          }
        }
      }
    `,
    contentUsageKey
  )

  if (!contentUsage) return null

  const occurrences = Relay.connectionToArray(contentUsage.occurrences)
  if (!occurrences.length) return null

  return (
    <>
      <DiscoDivider marginTop={0.25} marginBottom={0.75} />

      <DiscoText
        variant={"body-xs-500-uppercase"}
        color={"text.secondary"}
        marginLeft={0.5}
      >
        {"Events"}
      </DiscoText>

      <div className={classes.list}>
        {occurrences.map((occurrence, i) => (
          <ContentModuleEventsListItem
            key={occurrence.id}
            occurrenceKey={occurrence}
            testid={`${testid}.OccurrenceCard-${i}`}
            variant={variant}
          />
        ))}
      </div>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1.5),
  },
}))
