import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"

function ContentModuleListItemEmpty() {
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      className={classes.itemContainer}
    >
      <DiscoText variant={"body-lg-600"} color={"groovy.grey.300"}>
        {"No items"}
      </DiscoText>
    </Grid>
  )
}

export default ContentModuleListItemEmpty

const useStyles = makeUseStyles((theme) => ({
  itemContainer: {
    padding: theme.spacing(5.4, 0),
    background: theme.palette.background.paper,
    borderBottomLeftRadius: theme.measure.borderRadius.medium,
    borderBottomRightRadius: theme.measure.borderRadius.medium,
  },
}))
