/**
 * @generated SignedSource<<7f20cf43d6180cb737a713451b640f3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentModuleAdminDropdownFragment$data = {
  readonly id: string;
  readonly entity: ContentUsageEntity;
  readonly " $fragmentSpreads": FragmentRefs<"CreateContentModuleItemButtonFragment" | "UpdateContentModuleButtonFragment" | "DeleteContentUsageButtonFragment" | "ContentUsageAssociateEventsButtonFragment">;
  readonly " $fragmentType": "ContentModuleAdminDropdownFragment";
};
export type ContentModuleAdminDropdownFragment$key = {
  readonly " $data"?: ContentModuleAdminDropdownFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleAdminDropdownFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentModuleAdminDropdownFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateContentModuleItemButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateContentModuleButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteContentUsageButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageAssociateEventsButtonFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "68a778daaf5572a04d8839bd119b1c92";

export default node;
