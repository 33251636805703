import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import ContentModuleFormFields from "@/content-usage/modules/actions/ContentModuleFormFields"
import { ContentModuleFormQuery } from "@/content-usage/modules/actions/__generated__/ContentModuleFormQuery.graphql"
import {
  ContentModuleForm_UpdateContentUsageMutation,
  ContentUsageEntity,
  UpdateContentUsageInput,
} from "@/content-usage/modules/actions/__generated__/ContentModuleForm_UpdateContentUsageMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoButtonSkeleton, DiscoFormControlSkeleton } from "@disco-ui"
import { range } from "@utils/array/arrayUtils"
import DateUtils from "@utils/date/dateUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"
import { v4 as uuidv4 } from "uuid"

interface ContentModuleFormProps extends TestIDProps {
  parentEntityId: GlobalID
  parentEntity: ContentUsageEntity
  contentUsageId?: GlobalID
  onSubmit: () => void
  onCancel: () => void
  ordering?: number
}

export type ContentModuleFormState = UpdateContentUsageInput & {
  isAssociatingEvents?: boolean
}

function ContentModuleForm({
  parentEntityId,
  parentEntity,
  testid = "ContentModuleForm",
  onSubmit,
  onCancel,
  contentUsageId: editUsageId,
  ordering,
}: ContentModuleFormProps) {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()!
  const { filterContentLabelId } = useQueryParams<{ filterContentLabelId?: string }>()

  const { contentUsage } = useLazyLoadQuery<ContentModuleFormQuery>(
    graphql`
      query ContentModuleFormQuery($id: ID!, $skip: Boolean!) {
        contentUsage: node(id: $id) @skip(if: $skip) {
          ... on ContentUsage {
            id
            ordering
            releasedAt
            content {
              id
              name
              description
            }
            prerequisites {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    `,
    { id: editUsageId || "", skip: !editUsageId },
    { fetchPolicy: "store-and-network" }
  )

  const isCreation = !contentUsage
  const contentModuleLabel = ContentModuleUtils.getContentModuleLabel(parentEntity)

  // If we're updating an existing module, we need to use the existing contentUsageId
  // Otherwise, we need to generate a new one
  const contentUsageId = contentUsage?.id || Relay.toGlobalId("ContentUsage", uuidv4())

  const form = useFormStore<
    ContentModuleForm_UpdateContentUsageMutation,
    ContentModuleFormState
  >(
    graphql`
      mutation ContentModuleForm_UpdateContentUsageMutation(
        $input: UpdateContentUsageInput!
        $contentLabelIds: [ID!]
      ) {
        response: updateContentUsage(input: $input) {
          node {
            id
            createdAt
            prerequisites {
              totalCount
            }
            curriculum {
              id
              ...ContentModuleUtils_useCurriculumModuleIdsFragment
            }
            ...ContentModuleUtils_RefreshContentModulesFragment
              @arguments(contentLabelIds: $contentLabelIds)
            ...ContentModuleGridItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentUsageId,
      organizationId: activeOrganization.id,
      productId: activeProduct?.id,
      contentUsageInput: {
        entity: parentEntity,
        entityId: parentEntityId,
        ordering: getDefaultOrdering(),
        releasedAt: getDefaultReleasedAt(),
        occurrences: [],
        prerequisiteIds: contentUsage?.prerequisites
          ? Relay.connectionToArray(contentUsage.prerequisites).map((pc) => pc.id)
          : [],
      },
      contentId: contentUsage?.content?.id,
      contentType: "module",
      content: {
        name: contentUsage?.content?.name || "",
        description: contentUsage?.content?.description || "",
      },
      isAssociatingEvents: false,
    }
  )

  return (
    <Form
      id={testid}
      testid={testid}
      onSubmit={createOrUpdateContentModule}
      buttons={
        <>
          <DiscoButton
            color={"grey"}
            variant={"outlined"}
            onClick={onCancel}
            testid={`${testid}.cancel-button`}
          >
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton id={testid} form={form} testid={`${testid}.submit-button`}>
            {isCreation ? `Create ${contentModuleLabel}` : `Update ${contentModuleLabel}`}
          </Form.SubmitButton>
        </>
      }
    >
      <ContentModuleFormFields
        parentEntity={parentEntity}
        form={form}
        testid={testid}
        isCreation={isCreation}
      />
    </Form>
  )

  function getDefaultReleasedAt() {
    if (contentUsage?.releasedAt) return contentUsage.releasedAt
    if (parentEntity === "curriculum" && isCreation)
      return DateUtils.getOneWeekFromToday()
    return null
  }

  function getDefaultOrdering() {
    if (contentUsage) return contentUsage.ordering
    if (ordering !== undefined) return ordering
    if (parentEntity !== "curriculum") return 1
    return null
  }

  async function createOrUpdateContentModule() {
    try {
      const { isAssociatingEvents, ...input } = form.state
      const { didSave } = await form.submit(
        {
          ...input,
          contentUsageInput: {
            ...input.contentUsageInput,
            // Only include updates to released at if previously set
            ...(contentUsage?.releasedAt
              ? {
                  releasedAt: form.changedState.contentUsageInput?.releasedAt,
                }
              : {}),
            ordering: getDefaultOrdering(),
            occurrences: isAssociatingEvents
              ? input.contentUsageInput?.occurrences
              : undefined,
          },
        },
        {
          variables: {
            contentLabelIds: filterContentLabelId ? [filterContentLabelId] : undefined,
          },
          updater: (store, payload) => {
            const usageId = payload.response.node?.id
            if (!usageId) return

            if (isCreation) {
              const entity = store.get(parentEntityId)
              if (!entity) return

              ConnectionHandler.getConnections(
                entity,
                "CollectionModuleList__modules"
              ).forEach((connection) => {
                Relay.insertNodeIntoPaginatedConnection(
                  store,
                  connection,
                  store.get(usageId)!,
                  getDefaultOrdering() || 0
                )
              })

              ConnectionHandler.getConnections(
                entity,
                "CurriculumDashboardBlockCardView__modules"
              ).forEach((connection) => {
                Relay.insertNodeIntoPaginatedConnection(
                  store,
                  connection,
                  store.get(usageId)!,
                  getDefaultOrdering() || 0
                )
              })
            }
          },
        }
      )

      if (!didSave) return

      displaySuccessToast({
        message: `${contentModuleLabel} ${isCreation ? "created" : "updated"}`,
        testid: `${testid}.success-toast`,
      })
    } catch (error) {
      displayErrorToast(error)
    }
    onSubmit()
  }
}

function ContentModuleFormSkeleton() {
  return (
    <Form
      buttons={
        <>
          <DiscoButtonSkeleton width={"80px"} />
          <DiscoButtonSkeleton width={"130px"} />
        </>
      }
    >
      {range(3).map((i) => (
        <DiscoFormControlSkeleton key={i} variant={"one-column"} />
      ))}
    </Form>
  )
}

export default Relay.withSkeleton({
  component: observer(ContentModuleForm),
  skeleton: ContentModuleFormSkeleton,
})
