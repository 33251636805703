import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import { UpdateContentUsageInput } from "@/content-usage/__generated__/ContentModulesDragDropProvider_UpdateContentUsageMutation.graphql"
import { ContentUsageEntity } from "@/content-usage/__generated__/ContentUsageUtils_UseParentEntityContentUsageFragment.graphql"
import ContentUsagePrerequisiteItem from "@/content-usage/modules/actions/ContentUsagePrerequisiteItem"
import ContentUsagePrerequisiteMultiSelect from "@/content-usage/modules/actions/ContentUsagePrerequisiteMultiSelect"
import { ContentModulePrerequisitesFormQuery } from "@/content-usage/modules/actions/__generated__/ContentModulePrerequisitesFormQuery.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import WithEntitlement from "@components/entitlement/WithEntitlement"
import {
  DiscoAlert,
  DiscoDivider,
  DiscoFormControl,
  DiscoFormControlSkeleton,
  DiscoSection,
  DiscoSwitch,
  DiscoText,
} from "@disco-ui"
import { DiscoMultiSelectOption } from "@disco-ui/select/DiscoMultiSelect"
import { isBefore } from "date-fns"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useEffect, useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

type Module = {
  ordering?: number | null
  releasedAt?: string | null
  createdAt: string
}

interface Props {
  parentEntity: ContentUsageEntity
  form: FormStore<UpdateContentUsageInput>
}

function ContentModulePrerequisitesForm({ parentEntity, form }: Props) {
  const contentModuleLabel = ContentModuleUtils.getContentModuleLabel(parentEntity)
  const labels = useLabels()
  const activeProduct = useActiveProduct()!
  const classes = useStyles()

  const { product } = useLazyLoadQuery<ContentModulePrerequisitesFormQuery>(
    graphql`
      query ContentModulePrerequisitesFormQuery($id: ID!) {
        product: node(id: $id) {
          id
          ... on Product {
            id
            curriculum {
              modules {
                edges {
                  node {
                    id
                    ordering
                    releasedAt
                    createdAt
                    content {
                      name
                    }
                    ...ContentUsagePrerequisiteItemFragment
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeProduct.id,
    },
    { fetchPolicy: "network-only" }
  )

  const modules = Relay.connectionToArray(product?.curriculum?.modules)
  const selectedModules = modules
    .filter((m) => form.state.contentUsageInput?.prerequisiteIds?.includes(m.id))
    .map((m) => m.id)
  const isDisabled = modules.length < 1

  const viewedModule = modules.find((m) => m.id === form.state.contentUsageId)
  const sms = modules.filter((sm) =>
    form.state.contentUsageInput?.prerequisiteIds?.includes(sm.id)
  )

  const [prerequisiteError, setPrerequisiteError] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isToggleOn, setIsToggleOn] = useState(
    Boolean(form.state.contentUsageInput?.prerequisiteIds?.length)
  )

  useEffect(() => {
    let result = false
    for (const sm of sms) {
      if (hasError(sm, viewedModule)) {
        result = true
      }
    }
    setPrerequisiteError(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModules])

  const options = generateOptions()

  return (
    <>
      <DiscoDivider />
      <DiscoFormControl marginBottom={1} marginTop={1}>
        <WithEntitlement entitlement={"prerequisites"}>
          {({ hasEntitlement }) => (
            <DiscoSwitch
              testid={"ContentModulePrerequisiteFormField.toggle"}
              name={"toggle-release-date"}
              disabled={isDisabled || !hasEntitlement}
              checked={isToggleOn}
              onChange={handleToggle}
              label={<DiscoText variant={"body-md-600"}>{"Prerequisites"}</DiscoText>}
              sublabel={
                <DiscoText variant={"body-sm"} color={"groovy.neutral.700"}>{`Ensure ${
                  labels.product_member.plural
                } complete other ${pluralize(
                  contentModuleLabel
                )} before beginning this ${contentModuleLabel}.`}</DiscoText>
              }
            />
          )}
        </WithEntitlement>
      </DiscoFormControl>
      {isToggleOn && (
        <DiscoSection groovyDepths={"insideCard"} marginBottom={2.5}>
          <DiscoFormControl
            label={
              <DiscoText variant={"body-sm"} color={"groovy.neutral.700"}>{`${
                labels.product_member.plural
              } can access this ${contentModuleLabel} if they have completed all of the following ${pluralize(
                contentModuleLabel
              )}:`}</DiscoText>
            }
            marginBottom={0}
          >
            <ContentUsagePrerequisiteMultiSelect
              renderItem={renderItem}
              isOpen={isOpen}
              handleChange={handleChange}
              handleClose={toggleClose}
              handleOpen={toggleOpen}
              values={selectedModules}
              options={options}
            />
          </DiscoFormControl>
          {prerequisiteError && (
            <DiscoAlert
              severity={"error"}
              message={
                <DiscoText
                  variant={"body-sm"}
                  testid={"ContentModulePrerequisitesForm.error"}
                >
                  {
                    "Please note that only modules previous to this one can be added as prerequisites."
                  }
                </DiscoText>
              }
              marginTop={1}
              marginBottom={1}
            />
          )}
          {renderSelectedModules()}
        </DiscoSection>
      )}
    </>
  )

  function toggleOpen() {
    setIsOpen(true)
  }

  function toggleClose() {
    setIsOpen(false)
  }

  function handleChange(values: string[]) {
    if (!form.state.contentUsageInput?.prerequisiteIds) return
    form.state.contentUsageInput.prerequisiteIds = values
  }

  function generateOptions(): DiscoMultiSelectOption[] {
    const moduleOptions = modules
      .filter((m) => m.id !== form.state.contentUsageId)
      .map((module) => ({
        value: module.id,
        title: module.content.name || "",
        searchable: [module.content.name],
      }))

    return moduleOptions
  }

  function renderSelectedModules() {
    if (!sms) return null
    return sms.map((sm) => (
      <ContentUsagePrerequisiteItem
        key={sm.id}
        showError={hasError(sm, viewedModule)}
        classes={{ selectedItem: classes.selectedModule }}
        hideCheckbox
        onRemove={removeSelectedModule}
        contentModuleKey={sm}
        disableLink
      />
    ))
  }

  function renderItem(option: DiscoMultiSelectOption) {
    const module = modules.find((m) => m.id === option.value)
    if (!module) return null
    const selected = selectedModules.includes(module.id)
    return (
      <ContentUsagePrerequisiteItem
        key={module.id}
        contentModuleKey={module}
        selected={selected}
      />
    )
  }

  function removeSelectedModule(id: GlobalID) {
    if (!form.state.contentUsageInput?.prerequisiteIds) return

    form.state.contentUsageInput.prerequisiteIds =
      form.state.contentUsageInput.prerequisiteIds.filter((pid) => pid !== id)
  }

  function handleToggle() {
    setIsToggleOn((prevToggle) => !prevToggle)
    if (isToggleOn) {
      form.state.contentUsageInput = {
        ...form.state.contentUsageInput,
        prerequisiteIds: [],
      }
    }
  }
}

function hasError(module: Module, currentModule?: Module) {
  if (currentModule?.ordering === 0) return true
  if (!currentModule) return false
  if (currentModule.ordering) {
    if (!module.ordering) return false
    return currentModule.ordering < module.ordering
  } else if (currentModule?.releasedAt) {
    return isBefore(new Date(currentModule.releasedAt), new Date(module.releasedAt!))
  }

  return isBefore(new Date(currentModule.createdAt), new Date(module.createdAt))
}

const useStyles = makeUseStyles({
  selectedModule: {
    justifyContent: "space-between",
  },
})

function ContentModulePrerequisitesFormSkeleton() {
  return (
    <>
      <DiscoDivider />
      <DiscoFormControlSkeleton marginBottom={1} />
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(ContentModulePrerequisitesForm),
  skeleton: ContentModulePrerequisitesFormSkeleton,
})
