import { ContentUsagePrerequisiteItemFragment$key } from "@/content-usage/modules/actions/__generated__/ContentUsagePrerequisiteItemFragment.graphql"
import ContentModuleProgressIcon from "@/content-usage/modules/components/ContentModuleProgressIcon"
import ContentThumbnailWithDetails from "@/content/detail/ContentThumbnailWithDetails"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import {
  DiscoCheckbox,
  DiscoIcon,
  DiscoIconButton,
  DiscoLink,
  DiscoText,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"

interface ContentUsagePrerequisiteItemProps {
  contentModuleKey: ContentUsagePrerequisiteItemFragment$key
  selected?: boolean
  hideCheckbox?: boolean
  showError?: boolean
  onRemove?: (id: string) => void
  className?: string
  classes?: Partial<ClassNameMap<"selectedItem">>
  disableLink?: boolean
  rightTitleContent?: React.ReactNode
  rightContent?: React.ReactNode
  onClose?: () => void
}

export default function ContentUsagePrerequisiteItem({
  contentModuleKey,
  selected = false,
  hideCheckbox = false,
  showError = false,
  onRemove,
  onClose,
  disableLink = false,
  rightContent,
  rightTitleContent,
  classes: propClasses,
  className,
}: ContentUsagePrerequisiteItemProps) {
  const activeProduct = useActiveProduct()
  const usage = useFragment<ContentUsagePrerequisiteItemFragment$key>(
    graphql`
      fragment ContentUsagePrerequisiteItemFragment on ContentUsage {
        id
        releasedAt
        viewerHasCompleted
        ...ContentThumbnailWithDetails_ContentUsageFragment
        isLocked
        content {
          id
          isCurriculumModule
          ...ContentThumbnailWithDetails_ContentFragment
          name
          description
          children {
            totalCount
            edges {
              node {
                id
                viewerHasCompleted
                content {
                  type
                  contentLabelId
                }
              }
            }
          }
        }
      }
    `,
    contentModuleKey
  )
  const canManage = activeProduct?.viewerPermissions.has("content.manage") || false
  const classes = useStyles({ hasError: showError })
  const userTimeZone = useUserTimezone()

  const moduleItems = Relay.connectionToArray(usage.content.children)
  const completedItems = moduleItems.filter((item) => item.viewerHasCompleted)

  const progressStatus = usage.viewerHasCompleted
    ? "complete"
    : completedItems.length && moduleItems.length
    ? "incomplete"
    : "not-started"

  if (hideCheckbox)
    return (
      <DiscoLink
        {...getLinkProps()}
        className={classNames(classes.content, className)}
        onClick={onClose}
      >
        <div className={classNames(classes.selectedItem, propClasses?.selectedItem)}>
          <div
            data-testid={`ContentUsagePrerequisiteItem.list-item.${usage.content.name}`}
            className={classes.titleContainer}
          >
            <div className={classes.title}>
              {usage.content.isCurriculumModule ? (
                <DiscoText
                  truncateText={1}
                  variant={"body-md-600"}
                  color={showError ? "groovy.red.500" : "groovy.neutral.700"}
                >
                  {usage.content.name}
                </DiscoText>
              ) : (
                <ContentThumbnailWithDetails
                  contentUsageKey={usage}
                  contentKey={usage.content}
                  truncateText={1}
                />
              )}
              <div>{rightTitleContent}</div>
            </div>
            {usage.releasedAt && (
              <DiscoText
                variant={"body-sm"}
                color={showError ? "groovy.red.500" : "groovy.neutral.700"}
              >{`Schedule for release on ${formatDateWithOptions({
                timeZone: userTimeZone,
              })(new Date(usage.releasedAt))}`}</DiscoText>
            )}
          </div>
          {rightContent}
          {onRemove && (
            <DiscoIconButton
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                removeSelectedModule()
              }}
              size={"small"}
            >
              <DiscoIcon icon={"close"} className={classes.removeIcon} />
            </DiscoIconButton>
          )}

          {!canManage && usage.content.isCurriculumModule && !usage.isLocked && (
            <ContentModuleProgressIcon
              totalCompletedItems={completedItems.length}
              totalItems={moduleItems.length || 0}
              testid={`ContentUsagePrerequisiteItem.${progressStatus}`}
            />
          )}
        </div>
      </DiscoLink>
    )

  return (
    <DiscoDropdownItem
      testid={`ContentUsagePrerequisiteItem.${usage.content.name}.${
        selected ? "checked" : "unchecked"
      }`}
    >
      <DiscoCheckbox checked={selected} label={null} />
      <div>
        {usage.content.isCurriculumModule ? (
          <DiscoText truncateText={1}>{usage.content.name}</DiscoText>
        ) : (
          <ContentThumbnailWithDetails
            contentUsageKey={usage}
            contentKey={usage.content}
            truncateText={1}
          />
        )}
        {usage.releasedAt && (
          <DiscoText
            variant={"body-sm"}
            color={"groovy.neutral.700"}
          >{`Schedule for release on ${formatDateWithOptions({
            timeZone: userTimeZone,
          })(new Date(usage.releasedAt))}`}</DiscoText>
        )}
      </div>
    </DiscoDropdownItem>
  )

  function removeSelectedModule() {
    if (onRemove) onRemove(usage.id)
  }

  function getLinkProps() {
    if (disableLink || (usage.content.isCurriculumModule && moduleItems.length < 1))
      return { to: {} }
    return {
      to: {
        ...location,
        search: setSearchParams<GlobalDrawerParams<"contentUsage">>(location.search, {
          u: usage.content.isCurriculumModule
            ? Relay.fromGlobalId(moduleItems[0].id).id
            : Relay.fromGlobalId(usage.id).id,
        }),
      },
    }
  }
}

interface StyleProps {
  hasError: boolean
}

const useStyles = makeUseStyles((theme) => ({
  titleContainer: {
    width: "100%",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  selectedItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  removeIcon: ({ hasError }: StyleProps) => ({
    ...styleIf(hasError, {
      color: `${theme.palette.groovy.red[500]} !important`,
    }),
  }),
}))
