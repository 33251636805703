import DeleteContentUsageButton from "@/content-usage/buttons/DeleteContentUsageButton"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { ContentUsageGridItemFragment$key } from "@/content-usage/modules/grid/__generated__/ContentUsageGridItemFragment.graphql"
import ContentFooterSection from "@/content/detail/sections/ContentFooterSection"
import defaultUserCoverPhoto from "@assets/images/covers/default-user-cover-photo.png"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import DiscoVideo from "@disco-ui/video/DiscoVideo"
import { useTheme } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { graphql, useFragment } from "react-relay"

interface ContentUsageGridItemProps extends TestIDProps {
  contentUsageKey: ContentUsageGridItemFragment$key
  showOverflow?: boolean | null
  className?: string
}

function ContentUsageGridItem({
  contentUsageKey,
  testid = "ContentUsageGridItem",
  showOverflow = true,
  className,
}: ContentUsageGridItemProps) {
  const contentUsage = useFragment<ContentUsageGridItemFragment$key>(
    graphql`
      fragment ContentUsageGridItemFragment on ContentUsage {
        id
        showComments
        content {
          id
          type
          label
          name
          description
          coverPhoto
          coverVideo
          thumbnailUrl
          showCoverPlayIcon
          coverVideoAssetUsage {
            id
            asset {
              id
              ...DiscoVideoFragment
            }
          }
          productId
          ...ContentFooterSection_ContentFragment
        }
        ...DeleteContentUsageButtonFragment
        ...ContentFooterSection_ContentUsageFragment
      }
    `,
    contentUsageKey
  )

  const drawer = useContentUsageDrawer()
  const permissions = useActiveProductOrOrganizationPermissions()
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()
  const { content } = contentUsage
  const TESTID = `${testid}.${contentUsage.content.name}`

  return (
    <DiscoContainerButton
      className={classNames(classes.item, showOnHoverClasses.hoverable, className)}
      onClick={openDrawer}
      testid={TESTID}
    >
      {content.coverVideo || content.coverVideoAssetUsage ? (
        <DiscoVideo
          radius={"big"}
          src={content.coverVideo}
          // thumbnailUrl was created to replace coverPhoto, but both are still used so fallback to coverPhoto
          poster={content.thumbnailUrl || content.coverPhoto}
          assetKey={content.coverVideoAssetUsage?.asset}
          className={classes.videoWrapper}
          disablePlay
          playButton={{ hidden: !content.showCoverPlayIcon, size: "small" }}
          isGridItem
        />
      ) : (
        <CoverPhoto
          coverPhoto={content.thumbnailUrl || content.coverPhoto || defaultUserCoverPhoto}
          customClassName={classes.coverPhoto}
          isGridItem
        />
      )}

      <DiscoText testid={`${testid}.name`} variant={"body-md-600"} truncateText={1}>
        {content.name}
      </DiscoText>

      <ContentFooterSection
        contentUsageKey={contentUsage}
        contentKey={content!}
        readOnlyReactionButton
        testid={testid}
        onClick={goToCommentsPage}
        bookmarkKind={"content_usage"}
        hideCommentButton={!contentUsage.showComments}
        hideReactionsList
        hideText
      />

      {showOverflow && permissions.has("content.manage") && (
        <DiscoMoreActionsDropdown
          testid={testid}
          rotateIcon
          moreActionsButtonClassName={classNames(
            classes.moreActionsButton,
            showOnHoverClasses.showable
          )}
        >
          <DeleteContentUsageButton contentUsageKey={contentUsage}>
            {(buttonProps) => (
              <DiscoDropdownItem
                {...buttonProps}
                title={"Delete"}
                testid={`${TESTID}.delete-button`}
              />
            )}
          </DeleteContentUsageButton>
        </DiscoMoreActionsDropdown>
      )}
    </DiscoContainerButton>
  )

  function openDrawer() {
    drawer.open({
      drawerContentUsageId: contentUsage.id,
    })
  }

  function goToCommentsPage(e?: React.MouseEvent<HTMLButtonElement>) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    drawer.open({
      drawerContentUsageId: contentUsage!.id,
      drawerTab: "comments",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  item: {
    position: "relative",
    cursor: "pointer",
    display: "grid",
    width: "100%",
    textAlign: "left",
    justifyContent: "normal",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.big,
    transition: "box-shadow 0.2s ease-in-out",
    boxShadow: theme.palette.groovyDepths.insideCard,
    border: theme.palette.constants.borderSmall,
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    },
    backgroundColor: theme.palette.background.paper,
  },
  coverPhoto: {
    width: "100%",
    backgroundSize: "cover",
    borderRadius: theme.measure.borderRadius.medium,
  },
  moreActionsButton: {
    background: theme.palette.groovy.neutral[100],
    position: "absolute",
    zIndex: 2,
    top: theme.spacing(3),
    right: theme.spacing(3),
  },
  videoWrapper: {
    // To make the video container the same size as the cover photo
    aspectRatio: "2",
  },
}))

export function ContentUsageGridItemSkeleton() {
  const theme = useTheme()

  return (
    <Skeleton
      variant={"rect"}
      style={{
        borderRadius: theme.measure.borderRadius.big,
        width: "100%",
        height: "228px",
      }}
    />
  )
}

export default ContentUsageGridItem
