import { CONTENT_USAGE_DROPPABLE_TYPE } from "@/content-usage/ContentModulesDragDropProvider"
import ContentModuleListItemEmpty from "@/content-usage/modules/list/ContentModuleListItemEmpty"
import ContentUsageListItem, {
  ContentUsageListItemSkeleton,
} from "@/content-usage/modules/list/ContentUsageListItem"
import { ContentUsageListQuery } from "@/content-usage/modules/list/__generated__/ContentUsageListQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { range } from "@utils/array/arrayUtils"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { graphql, useLazyLoadQuery } from "react-relay"

type Props = {
  contentId: GlobalID
  filterContentLabelIds?: GlobalID[]
  showManageActions: boolean
  isCollapsed: boolean
  canReorder: boolean
}

function ContentUsageList(props: Props) {
  const { contentId, filterContentLabelIds, showManageActions, isCollapsed, canReorder } =
    props

  const { content } = useLazyLoadQuery<ContentUsageListQuery>(
    graphql`
      query ContentUsageListQuery(
        $id: ID!
        $contentLabelIds: [ID!]
        $roles: [ProductRole!]
      ) {
        content: node(id: $id) {
          ... on Content {
            id
            children(contentLabelIds: $contentLabelIds) {
              edges {
                node {
                  id
                  prerequisites {
                    totalCount
                  }
                  ...ContentUsageListItemFragment @arguments(roles: $roles)
                }
              }
            }
          }
        }
      }
    `,
    {
      id: contentId,
      contentLabelIds: filterContentLabelIds,
    },
    { fetchPolicy: "network-only" }
  )

  if (!content?.id) return null
  const usageItems = Relay.connectionToArray(content.children)

  return (
    <Droppable
      type={CONTENT_USAGE_DROPPABLE_TYPE}
      droppableId={content.id}
      isDropDisabled={isCollapsed}
    >
      {(droppableProvided) => (
        <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
          <ul>
            {usageItems.map((item, index) => (
              <Draggable
                key={item.id}
                isDragDisabled={
                  !showManageActions ||
                  !canReorder ||
                  Boolean(item.prerequisites?.totalCount)
                }
                draggableId={item.id}
                index={index}
              >
                {(draggableProvided) => (
                  <div
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    ref={draggableProvided.innerRef}
                  >
                    <ContentUsageListItem
                      testid={`ContentUsageListItem.${index}`}
                      contentUsageKey={item}
                      hideReorderIcon={!canReorder}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {Boolean(usageItems.length) && droppableProvided.placeholder}
          </ul>
          {!usageItems.length && <ContentModuleListItemEmpty />}
        </div>
      )}
    </Droppable>
  )
}

function ContentUsageListSkeleton() {
  return (
    <>
      {range(4).map((i) => (
        <ContentUsageListItemSkeleton key={i} />
      ))}
    </>
  )
}

export default Relay.withSkeleton({
  component: ContentUsageList,
  skeleton: ContentUsageListSkeleton,
})
