import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import {
  InlineContentDrawerFormState,
  InlineContentDrawerMode,
} from "@/content-usage/drawer/InlineContentDrawerTemplate"
import ContentCTABannerTemplate from "@/content-usage/drawer/quizzes/ContentCTABannerTemplate"
import { ContentUsageAssignmentSubmitBannerFragment$key } from "@/content-usage/drawer/submissions/__generated__/ContentUsageAssignmentSubmitBannerFragment.graphql"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import FormStore from "@/core/form/store/FormStore"
import SubmitAssignmentForm from "@/product/course/assignments/submission/SubmitAssignmentForm"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import { useTheme } from "@material-ui/core/styles"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  mode: InlineContentDrawerMode
  form: FormStore<InlineContentDrawerFormState>
  contentUsageKey: ContentUsageAssignmentSubmitBannerFragment$key | null
  contentLabel: string
}

function ContentUsageAssignmentSubmitBanner(props: Props) {
  const {
    mode,
    form,
    contentUsageKey,
    contentLabel,
    testid = "ContentUsageAssignmentSubmitBanner",
  } = props

  const contentUsage = useFragment<ContentUsageAssignmentSubmitBannerFragment$key>(
    graphql`
      fragment ContentUsageAssignmentSubmitBannerFragment on ContentUsage {
        id
        submissionMethod
        viewerSubmission {
          id
        }
        ...ContentUsageUtils_useSubmissionDeadlineFragment
        ...SubmitAssignmentFormFragment
      }
    `,
    contentUsageKey
  )

  const classes = useStyles()
  const theme = useTheme()

  const drawer = useGlobalDrawer("contentUsage")
  const timezone = useUserTimezone()

  const formState = form.state.contentUsageInput
  const submissionMethod =
    mode === "read" ? contentUsage!.submissionMethod : formState?.submissionMethod

  const { dueAt, submittedAt, isLateSubmission } =
    ContentUsageUtils.useSubmissionDeadline({
      mode,
      contentForm: form,
      contentUsageKey: contentUsage,
    })

  // No banner for non-submission assignments
  if (submissionMethod !== "submission") {
    return null
  }

  // Show submission form in edit mode or non-submitted
  if (mode !== "read" || !contentUsage?.viewerSubmission) {
    return (
      <ContentCTABannerTemplate
        message={
          <SubmitAssignmentForm
            mode={mode}
            contentForm={form}
            contentUsageKey={contentUsage}
            contentLabel={contentLabel}
          />
        }
      />
    )
  }

  // Submitted confirmation
  return (
    <ContentCTABannerTemplate
      message={
        <div className={classes.submittedMessageContainer}>
          {isLateSubmission && dueAt && (
            <DiscoTooltip
              content={`This submission was due on ${ContentUsageUtils.getFormattedDueDate(
                dueAt,
                timezone,
                "@"
              )}`}
            >
              <div>
                <DiscoIcon icon={"time"} color={theme.palette.error.main} />
              </div>
            </DiscoTooltip>
          )}
          <div>
            <DiscoText
              variant={"body-md-600"}
              color={"groovy.neutral.700"}
              marginBottom={0.5}
            >
              {"Submission Received!"}
            </DiscoText>
            {submittedAt && (
              <DiscoText variant={"body-md"} color={"groovy.neutral.500"}>
                {`You completed this ${contentLabel} on `}
                <span
                  className={classes.submittedDate}
                  style={{
                    color: isLateSubmission
                      ? theme.palette.error.main
                      : theme.palette.text.primary,
                  }}
                >
                  {formatDateWithOptions({
                    format: isLateSubmission
                      ? DATE_FORMAT.DEFAULT_FULL_MONTH_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT
                      : DATE_FORMAT.DEFAULT_FULL_MONTH,
                    timeZone: timezone,
                  })(submittedAt)}
                </span>
              </DiscoText>
            )}
          </div>
        </div>
      }
      buttons={
        <DiscoButton
          testid={`${testid}.view-submission`}
          onClick={() =>
            drawer.setParams({
              ...drawer.params,
              drawerSubmissionsTab: "submissions",
              submissionId: contentUsage.viewerSubmission!.id,
            })
          }
        >
          {"Review Submission"}
        </DiscoButton>
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  submittedDate: {
    ...theme.typography["body-md"],
    ...theme.typography.modifiers.fontWeight[600],
  },
  submittedMessageContainer: {
    display: "grid",
    gap: theme.spacing(1),
    gridTemplateColumns: "auto 1fr",
  },
}))

export default observer(ContentUsageAssignmentSubmitBanner)
