import ContentModuleForm from "@/content-usage/modules/actions/ContentModuleForm"
import { UpdateContentModuleButtonFragment$key } from "@/content-usage/modules/actions/__generated__/UpdateContentModuleButtonFragment.graphql"
import ContentUtils from "@/content/util/contentUtils"
import { DiscoModal, DiscoText } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import useDisclosure from "@utils/hook/useDisclosure"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface UpdateContentModuleButtonProps {
  contentUsageKey: UpdateContentModuleButtonFragment$key
  children: OverridableDiscoButtonChildren
}

function UpdateContentModuleButton({
  contentUsageKey,
  children,
}: UpdateContentModuleButtonProps) {
  const contentUsage = useFragment<UpdateContentModuleButtonFragment$key>(
    graphql`
      fragment UpdateContentModuleButtonFragment on ContentUsage {
        id
        entity
        entityId
        content {
          type
          label
        }
      }
    `,
    contentUsageKey
  )

  const contentLabel = ContentUtils.useContentLabel({
    content: contentUsage.content,
    entity: contentUsage.entity,
  })
  const parentEntity = contentUsage.entity

  const {
    isOpen: isEditFormOpen,
    onOpen: onEditFormOpen,
    onClose: onEditFormClose,
  } = useDisclosure()

  const description = getDescription()

  return (
    <>
      <OverridableDiscoButton onClick={onEditFormOpen}>{children}</OverridableDiscoButton>

      <DiscoModal
        isOpen={isEditFormOpen}
        onClose={onEditFormClose}
        title={`Update ${contentLabel}`}
        testid={`Update${contentLabel}Modal`}
        modalContentLabel={`Update ${contentLabel} Dialog`}
        minHeight={parentEntity === "collection" ? "unset" : undefined}
        width={parentEntity === "curriculum" ? "720px" : undefined}
        maxWidth={"90vw"}
        buttons
        body={
          <div>
            {description && (
              <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={2.5}>
                {description}
              </DiscoText>
            )}
            <ContentModuleForm
              parentEntity={contentUsage.entity}
              parentEntityId={contentUsage.entityId}
              contentUsageId={contentUsage.id}
              onSubmit={onEditFormClose}
              onCancel={onEditFormClose}
            />
          </div>
        }
      />
    </>
  )

  function getDescription() {
    switch (contentLabel) {
      case "Folder":
        return "Update this folder to organize resources, pages and more."
    }
    return null
  }
}

export default observer(UpdateContentModuleButton)
