/**
 * @generated SignedSource<<731069353e1db9e1c13acfee8939e4e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type OrderByInput = {
  direction: OrderDirection;
  field: string;
};
export type ContentModuleCompletionModalQuery$variables = {
  id: string;
  orderParentPathwaysBy?: OrderByInput | null;
};
export type ContentModuleCompletionModalQuery$data = {
  readonly contentUsage: {
    readonly id?: string;
    readonly viewerCompletion?: {
      readonly createdAt: string;
    } | null;
    readonly content?: {
      readonly name: string | null;
    };
    readonly product?: {
      readonly id: string;
      readonly name: string;
      readonly slug: string;
      readonly viewerMembership: {
        readonly completedAt: string | null;
      } | null;
      readonly viewerParentPathwayProducts: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly name: string;
            readonly slug: string;
            readonly viewerMembership: {
              readonly completedAt: string | null;
            } | null;
            readonly childPathwayGroups: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly title: string;
                  readonly viewerMembership: {
                    readonly completedAt: string | null;
                  } | null;
                };
              }>;
            };
          };
        }>;
      };
    } | null;
    readonly curriculum?: {
      readonly modules: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly releasedAt: string | null;
            readonly viewerCompletion: {
              readonly createdAt: string;
            } | null;
            readonly content: {
              readonly children: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly id: string;
                  };
                }>;
              } | null;
            };
          };
        }>;
      };
    } | null;
  } | null;
};
export type ContentModuleCompletionModalQuery = {
  variables: ContentModuleCompletionModalQuery$variables;
  response: ContentModuleCompletionModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderParentPathwaysBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentCompletion",
  "kind": "LinkedField",
  "name": "viewerCompletion",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductMembership",
  "kind": "LinkedField",
  "name": "viewerMembership",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderParentPathwaysBy"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "releasedAt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "ContentUsageNodeConnection",
  "kind": "LinkedField",
  "name": "children",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "children(first:1)"
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentCompletion",
  "kind": "LinkedField",
  "name": "viewerCompletion",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v15 = [
  (v7/*: any*/),
  (v2/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductMembership",
  "kind": "LinkedField",
  "name": "viewerMembership",
  "plural": false,
  "selections": (v15/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentModuleCompletionModalQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "ProductNodeConnection",
                    "kind": "LinkedField",
                    "name": "viewerParentPathwayProducts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PathwayGroupNodeConnection",
                                "kind": "LinkedField",
                                "name": "childPathwayGroups",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PathwayGroupNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PathwayGroup",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          (v11/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PathwayGroupMembership",
                                            "kind": "LinkedField",
                                            "name": "viewerMembership",
                                            "plural": false,
                                            "selections": (v8/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Curriculum",
                "kind": "LinkedField",
                "name": "curriculum",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeConnection",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsageNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentUsage",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v12/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Content",
                                "kind": "LinkedField",
                                "name": "content",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContentModuleCompletionModalQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "ProductNodeConnection",
                    "kind": "LinkedField",
                    "name": "viewerParentPathwayProducts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v16/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PathwayGroupNodeConnection",
                                "kind": "LinkedField",
                                "name": "childPathwayGroups",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PathwayGroupNodeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PathwayGroup",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          (v11/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PathwayGroupMembership",
                                            "kind": "LinkedField",
                                            "name": "viewerMembership",
                                            "plural": false,
                                            "selections": (v15/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Curriculum",
                "kind": "LinkedField",
                "name": "curriculum",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeConnection",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsageNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentUsage",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v12/*: any*/),
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Content",
                                "kind": "LinkedField",
                                "name": "content",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "704cf6b5fe813de37d9a0c6b5e81c5bf",
    "id": null,
    "metadata": {},
    "name": "ContentModuleCompletionModalQuery",
    "operationKind": "query",
    "text": "query ContentModuleCompletionModalQuery(\n  $id: ID!\n  $orderParentPathwaysBy: OrderByInput\n) {\n  contentUsage: node(id: $id) {\n    __typename\n    ... on ContentUsage {\n      id\n      viewerCompletion {\n        createdAt\n        id\n      }\n      content {\n        name\n        id\n      }\n      product {\n        id\n        name\n        slug\n        viewerMembership {\n          completedAt\n          id\n        }\n        viewerParentPathwayProducts(orderBy: $orderParentPathwaysBy) {\n          edges {\n            node {\n              id\n              name\n              slug\n              viewerMembership {\n                completedAt\n                id\n              }\n              childPathwayGroups {\n                edges {\n                  node {\n                    id\n                    title\n                    viewerMembership {\n                      completedAt\n                      id\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n      curriculum {\n        modules {\n          edges {\n            node {\n              id\n              releasedAt\n              viewerCompletion {\n                createdAt\n                id\n              }\n              content {\n                children(first: 1) {\n                  edges {\n                    node {\n                      id\n                    }\n                  }\n                }\n                id\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef9ae2a709c00e9c229bf744473b9c37";

export default node;
