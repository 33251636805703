import { CloneContentModuleFormState } from "@/content-usage/buttons/CloneContentModuleButton"
import { CloneContentModuleContentCloneMethod } from "@/content-usage/buttons/__generated__/CloneContentModuleButtonMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import { DiscoSelect, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"

interface Props {
  form: FormStore<CloneContentModuleFormState>
}

function CloneContentModuleContentCloneMethodFormFields({ form }: Props) {
  useEffect(() => {
    getDefaultCloneMethodMethod()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.destinationTab])

  return (
    <>
      <DiscoText
        testid={"CloneContentModuleContentCloneMethodFormFields.description"}
        color={"text.secondary"}
        marginBottom={2.5}
      >{`Choose a method for cloning this module. Duplicated content will operate independently from the original. Reused content when updated in one place will update everywhere it is used.`}</DiscoText>

      <DiscoSelect
        testid={"CloneContentModuleContentCloneMethodFormFields.select-method"}
        placeholder={"Select Method"}
        disableClearable
        value={form.state.contentCloneMethod}
        onChange={(method) => (form.state.contentCloneMethod = method)}
        options={[
          {
            value: "duplicate" as CloneContentModuleContentCloneMethod,
            title: "Duplicate",
          },
          {
            value: "reuse" as CloneContentModuleContentCloneMethod,
            title: "Reuse",
          },
        ]}
      />
    </>
  )

  function getDefaultCloneMethodMethod() {
    if (form.state.destinationTab === "across_experiences") {
      form.state.contentCloneMethod = "reuse"
    } else if (form.state.destinationTab === "within_entity") {
      form.state.contentCloneMethod = "duplicate"
    }
  }
}

export default observer(CloneContentModuleContentCloneMethodFormFields)
