import { ContentModuleReleaseCountdownFragment$key } from "@/content-usage/modules/components/__generated__/ContentModuleReleaseCountdownFragment.graphql"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { Grid } from "@material-ui/core"
import useCountDownTimer, { countdownDataToMessage } from "@utils/hook/useCountDownTimer"
import { TestIDProps } from "@utils/typeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface ContentModuleReleaseCountdownProps extends TestIDProps {
  contentUsageKey: ContentModuleReleaseCountdownFragment$key
}

function ContentModuleReleaseCountdown({
  contentUsageKey,
}: ContentModuleReleaseCountdownProps) {
  const classes = useStyles()

  const contentUsage = useFragment<ContentModuleReleaseCountdownFragment$key>(
    graphql`
      fragment ContentModuleReleaseCountdownFragment on ContentUsage {
        releasedAt
      }
    `,
    contentUsageKey
  )

  const countdown = useCountDownTimer(new Date(contentUsage.releasedAt!))

  if (countdown.delta <= 0) return null

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"center"}
      className={classes.itemContainer}
    >
      <DiscoText
        variant={"body-lg-600"}
        color={"groovy.grey.300"}
        testid={"CurriculumSectionListItemReleaseCountdown.countdown-text"}
      >{`Releases in ${countdownDataToMessage(countdown)}`}</DiscoText>
    </Grid>
  )
}

const useStyles = makeUseStyles((theme) => ({
  itemContainer: {
    padding: theme.spacing(5.4, 0),
    background: theme.palette.background.paper,
    borderBottomLeftRadius: theme.measure.borderRadius.medium,
    borderBottomRightRadius: theme.measure.borderRadius.medium,
  },
}))

export default ContentModuleReleaseCountdown
