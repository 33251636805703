import Badge from "@/admin/experiences/badges/Badge"
import { CollectionDropdownItemFragment$key } from "@/content-usage/forms/__generated__/CollectionDropdownItemFragment.graphql"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  collectionAppKey: CollectionDropdownItemFragment$key
  className?: string
}

function CollectionDropdownItem(props: Props) {
  const { testid, collectionAppKey, className } = props

  const productApp = useFragment<CollectionDropdownItemFragment$key>(
    graphql`
      fragment CollectionDropdownItemFragment on ProductApp {
        id
        customAppTitle
        badge {
          ...BadgeFragment
        }
        product {
          name
        }
      }
    `,
    collectionAppKey
  )

  return (
    <DiscoDropdownItem
      className={className}
      testid={testid}
      icon={<Badge badgeKey={productApp.badge!} size={24} />}
      title={productApp.customAppTitle || "Collection"}
      subtitle={productApp.product?.name}
    />
  )
}

export default CollectionDropdownItem
