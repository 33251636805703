import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoIconButton, DiscoText } from "@disco-ui"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { TestIDProps } from "@utils/typeUtils"
import { formatInTimeZone } from "date-fns-tz"

interface Props extends TestIDProps {
  onCalendarClick: VoidFunction
  title: string
  dueDate: Date
}

function CloneContentDueDateItem({ testid, onCalendarClick, title, dueDate }: Props) {
  const classes = useStyles()
  const userTimezone = useUserTimezone()

  return (
    <div data-testid={testid} className={classes.container}>
      <div>
        <DiscoText testid={`${testid}.title`} variant={"body-md-700"} marginBottom={0.5}>
          {title}
        </DiscoText>
        <DiscoText
          testid={`${testid}.datetime`}
          variant={"body-sm"}
          color={"groovy.grey.400"}
        >
          {formatInTimeZone(
            dueDate,
            userTimezone,
            DATE_FORMAT.DEFAULT_FULL_MONTH_WITH_SHORT_TIME_FORMAT_WITH_OFFSET_AND_AT
          )}
        </DiscoText>
      </div>
      <div>
        <DiscoIconButton testid={`${testid}.open-date-picker`} onClick={onCalendarClick}>
          <DiscoIcon icon={"calendar"} />
        </DiscoIconButton>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))

export default CloneContentDueDateItem
