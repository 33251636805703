import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import ContentUsagePrerequisiteList from "@/content-usage/ContentUsagePrerequisiteList"
import { ContentUsageLockIconFragment$key } from "@/content-usage/modules/list/__generated__/ContentUsageLockIconFragment.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import LockIcon from "@/core/ui/iconsax/linear/lock-1.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoIconButton, DiscoText, DiscoTooltip } from "@disco-ui"
import useDisclosure from "@utils/hook/useDisclosure"
import usePermissions from "@utils/hook/usePermissions"
import { graphql, useFragment } from "react-relay"

interface ContentUsageLockIconProps {
  usageKey: ContentUsageLockIconFragment$key
  disabled?: boolean
  width?: number
  height?: number
  className?: string
  color?: string
}

export default function ContentUsageLockIcon({
  usageKey,
  disabled = false,
  className,
  width = 24,
  height = 24,
  color,
}: ContentUsageLockIconProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const classes = useStyles({ width, height, color })
  const labels = useLabel("product_member")

  const usage = useFragment<ContentUsageLockIconFragment$key>(
    graphql`
      fragment ContentUsageLockIconFragment on ContentUsage {
        id
        entity
        content {
          name
        }
        ...UpdateContentModuleButtonFragment
        ...usePermissionsFragment
      }
    `,
    usageKey
  )
  const permissions = usePermissions(usage)
  const canManage = permissions.has("content.manage")
  if (!usage || !usage.content) return null
  const contentUsageLabel = ContentModuleUtils.getContentModuleLabel(usage.entity!)

  return (
    <>
      <DiscoTooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        title={`This ${contentUsageLabel} has prerequisites.`}
        titleVariant={"body-sm-600"}
        interactive
        content={
          <>
            <DiscoText variant={"body-sm"}>
              {canManage
                ? `Until certain content is successfully completed, ${labels.plural} cannot access this module.`
                : "You cannot access this content until you've completed the prerequisites."}
            </DiscoText>
            {canManage && (
              <DiscoButton
                testid={"ContentUsageLockIcon.tooltip.edit-prerequisites"}
                className={classes.prerequisiteSetupBtn}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onOpen()
                }}
              >
                {"Prerequisite Setup"}
              </DiscoButton>
            )}
          </>
        }
      >
        <span data-testid={"ContentUsageLockIcon.lockIcon"}>
          <DiscoIconButton
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onOpen()
            }}
            disabled={disabled || canManage}
            className={classes.icon}
          >
            <LockIcon className={className} />
          </DiscoIconButton>
        </span>
      </DiscoTooltip>

      <ContentUsagePrerequisiteList
        contentUsageId={usage.id}
        isOpen={isOpen}
        openPrerequisites={onOpen}
        closePrerequisitesModal={onClose}
        testid={`ContentUsageLockIcon.prerequisitesList.${usage.content?.name}`}
      />
    </>
  )
}

interface StyleProps {
  height: number
  width: number
  color?: string
}

const useStyles = makeUseStyles((theme) => ({
  icon: ({ width, height, color }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    width: `${width}px`,
    height: `${height}px`,
    padding: theme.spacing(0),

    "& svg": {
      color: color
        ? `${color} !important`
        : `${theme.palette.groovy.neutral[700]} !important`,
    },
  }),
  prerequisiteSetupBtn: {
    marginTop: theme.spacing(1.5),
    cursor: "pointer",
  },
  tooltip: {
    width: "400px",
  },
}))
