/**
 * @generated SignedSource<<6b0f8232f7fa2b6a20507f8abf7d943d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateContentModuleItemButtonFragment$data = {
  readonly id: string;
  readonly entity: ContentUsageEntity;
  readonly " $fragmentSpreads": FragmentRefs<"CreateCollectionModuleItemContentUsageButtonFragment" | "CreateCurriculumModuleItemContentUsageButtonFragment">;
  readonly " $fragmentType": "CreateContentModuleItemButtonFragment";
};
export type CreateContentModuleItemButtonFragment$key = {
  readonly " $data"?: CreateContentModuleItemButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateContentModuleItemButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateContentModuleItemButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateCollectionModuleItemContentUsageButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateCurriculumModuleItemContentUsageButtonFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "b99325b5d8a0cb0aac9e09e3d5d246df";

export default node;
