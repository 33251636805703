import CreateCollectionModuleItemContentUsageButton from "@/content-usage/buttons/add-button/CreateCollectionModuleItemContentUsageButton"
import CreateCurriculumModuleItemContentUsageButton from "@/content-usage/buttons/add-button/CreateCurriculumModuleItemContentUsageButton"
import { CreateContentModuleItemButtonFragment$key } from "@/content-usage/modules/actions/__generated__/CreateContentModuleItemButtonFragment.graphql"
import { OverridableDiscoButtonChildren } from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface CreateContentModuleItemButtonProps extends TestIDProps {
  children: OverridableDiscoButtonChildren
  contentUsageKey: CreateContentModuleItemButtonFragment$key
}

function CreateContentModuleItemButton({
  testid = "CreateContentModuleItemButton",
  children,
  contentUsageKey,
}: CreateContentModuleItemButtonProps) {
  const moduleContentUsage = useFragment<CreateContentModuleItemButtonFragment$key>(
    graphql`
      fragment CreateContentModuleItemButtonFragment on ContentUsage {
        id
        entity
        ...CreateCollectionModuleItemContentUsageButtonFragment
        ...CreateCurriculumModuleItemContentUsageButtonFragment
      }
    `,
    contentUsageKey
  )

  switch (moduleContentUsage.entity) {
    case "collection":
      return (
        <CreateCollectionModuleItemContentUsageButton
          testid={testid}
          moduleContentUsageKey={moduleContentUsage}
        >
          {children}
        </CreateCollectionModuleItemContentUsageButton>
      )
    case "curriculum":
      return (
        <CreateCurriculumModuleItemContentUsageButton
          testid={testid}
          moduleContentUsageKey={moduleContentUsage}
        >
          {children}
        </CreateCurriculumModuleItemContentUsageButton>
      )
  }
  return null
}

export default observer(CreateContentModuleItemButton)
