import { ContentModuleEventsListItem_OccurrenceFragment$key } from "@/content-usage/modules/list/__generated__/ContentModuleEventsListItem_OccurrenceFragment.graphql"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import { useLabels } from "@/core/context/LabelsContext"
import MemberGroupEventTagList from "@/product/common/member-group/common/tag/MemberGroupEventTagList"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import DaySquare from "@components/date/day-square/DaySquare"
import { DiscoSection, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import pluralize from "pluralize"
import { useFragment } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export interface ContentModuleEventsListItemProps extends TestIDProps {
  occurrenceKey: ContentModuleEventsListItem_OccurrenceFragment$key
  variant?: "default" | "compact"
}

function ContentModuleEventsListItem({
  testid = "ContentModuleEventsListItem",
  occurrenceKey,
  variant = "default",
}: ContentModuleEventsListItemProps) {
  const classes = useStyles({ variant })
  const timeZone = useUserTimezone()
  const { admin_member: membersLabel } = useLabels()
  const history = useHistory()

  const occurrence = useFragment<ContentModuleEventsListItem_OccurrenceFragment$key>(
    graphql`
      fragment ContentModuleEventsListItem_OccurrenceFragment on Occurrence {
        id
        name
        coverPhoto
        isComplete
        datetimeRange
        product {
          cover
          name
        }
        event {
          id
          ...MemberGroupEventTagListFragment
          ...usePermissionsFragment
          memberGroups {
            totalCount
          }
        }
        rsvps {
          totalCount
        }
        attendees {
          totalCount
        }
      }
    `,
    occurrenceKey
  )

  const coverPhoto = occurrence.coverPhoto || occurrence.product.cover
  const memberCount = occurrence.isComplete
    ? occurrence.attendees?.totalCount || 0
    : occurrence.rsvps?.totalCount || 0
  const attendText = occurrence.isComplete ? "attended" : "attending"

  return (
    <DiscoContainerButton onClick={handleClick} testid={testid}>
      <DiscoSection className={classes.section}>
        <div className={classes.lhs}>
          <div className={classes.cover}>
            {occurrence.isComplete && (
              <DiscoTag name={"Ended"} className={classes.endedTag} />
            )}
            <CoverPhoto coverPhoto={coverPhoto} customClassName={classes.coverPhoto} />
          </div>

          {variant === "default" && (
            <DaySquare
              testid={`${testid}.DaySquare`}
              date={new Date(occurrence.datetimeRange[0])}
              formatterOptions={{ timeZone }}
              customClassName={classes.daySquare}
            />
          )}

          <div>
            <DiscoText variant={"body-md-600"} truncateText={2}>
              {occurrence.name}
            </DiscoText>
            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {formatDateWithOptions({
                timeZone,
                format:
                  DATE_FORMAT.REVERSE_DATE_WITH_WEEK_DAY_WITH_TIME_WITHOUT_YEAR_WITH_AT,
              })(new Date(occurrence.datetimeRange[0]))}
            </DiscoText>
          </div>
        </div>

        {variant === "default" && (
          <div className={classes.rhs}>
            <div className={classes.groups}>
              {Boolean(occurrence.event.memberGroups.totalCount) && (
                <MemberGroupEventTagList
                  hideDefault
                  eventKey={occurrence.event}
                  testid={testid}
                  hideAfter={2}
                  className={classes.groupsList}
                />
              )}
            </div>

            <div className={classes.rsvps}>
              <DiscoText variant={"body-sm"} color={"text.secondary"}>
                {`${memberCount} ${pluralize(
                  membersLabel.singular,
                  memberCount
                )} ${attendText}`}
              </DiscoText>
            </div>
          </div>
        )}
      </DiscoSection>
    </DiscoContainerButton>
  )

  function handleClick() {
    history.push({
      ...location,
      search: setSearchParams<GlobalDrawerParams<"event">>(location.search, {
        drawerOccurrenceId: occurrence.id,
        drawerEventTab: "details",
      }),
    })
  }
}

type StyleProps = {
  variant: ContentModuleEventsListItemProps["variant"]
}

const useStyles = makeUseStyles((theme) => ({
  section: ({ variant }: StyleProps) => ({
    marginBottom: theme.spacing(1.5),
    display: "flex",
    padding: theme.spacing(1.5),
    justifyContent: "space-between",

    "&:hover": {
      transition: theme.transitions.create(["boxShadow", "cursor"], {
        duration: theme.transitions.duration.standard,
        easing: theme.transitions.easing.easeInOut,
      }),
      cursor: "pointer",
      background: theme.palette.groovy.neutral[100],
    },

    ...styleIf(variant === "default", {
      boxShadow: theme.palette.groovyDepths.insideCard,
      border: theme.palette.constants.borderSmall,

      "&:hover": {
        boxShadow: theme.palette.groovyDepths.boxShadow,
      },
    }),
  }),
  lhs: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(1.5),
    marginRight: theme.spacing(1),
  },
  rhs: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    justifyContent: "space-between",
  },
  daySquare: {
    flexShrink: 0,
  },
  cover: {
    maxWidth: "120px",
    minWidth: "120px",
    maxHeight: "60px",
    display: "flex",
    position: "relative",
    width: "100%",
  },
  endedTag: {
    position: "absolute",
    zIndex: theme.zIndex.raise1,
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  coverPhoto: {
    width: "100%",
    borderRadius: theme.measure.borderRadius.medium,
  },
  groups: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
  },
  groupsList: {
    margin: 0,
  },
  rsvps: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
  },
}))

export default ContentModuleEventsListItem
