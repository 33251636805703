import { CurriculumConnectSlackOnboardingDrawerQuery } from "@/content-usage/drawer/system-tasks/__generated__/CurriculumConnectSlackOnboardingDrawerQuery.graphql"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import StepperContextProvider, { StepperContext } from "@/core/context/StepperContext"
import MemberOnboardingSlackContent from "@/member-onboarding/slack-flow/MemberOnboardingSlackContent"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDrawer, DiscoDrawerHeader } from "@disco-ui"
import { Fade } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useLazyLoadQuery } from "react-relay"

export interface CurriculumConnectSlackOnboardingDrawerProps extends TestIDProps {
  contentUsageId: GlobalID
}

function CurriculumConnectSlackOnboardingDrawer({
  testid = "CurriculumConnectSlackOnboardingDrawer",
  contentUsageId,
}: CurriculumConnectSlackOnboardingDrawerProps) {
  const contentUsageDrawer = useGlobalDrawer("contentUsage")
  const { usage } = useLazyLoadQuery<CurriculumConnectSlackOnboardingDrawerQuery>(
    graphql`
      query CurriculumConnectSlackOnboardingDrawerQuery($id: ID!) {
        usage: node(id: $id) {
          ... on ContentUsage {
            id
            ...MemberOnboardingSlackContentFragment
          }
        }
      }
    `,
    {
      id: contentUsageId,
    }
  )

  const classes = useStyles()

  if (!usage) return null

  const steps = [usage]

  return (
    <>
      <StepperContextProvider steps={[0, steps.length]} onAfterLast={handleCloseDrawer}>
        <StepperContext.Consumer>
          {(ctx) => {
            const {
              steps: [activeStep, _],
            } = ctx!
            return (
              <DiscoDrawer
                containerClasses={{ drawerContainer: classes.drawer }}
                open
                fullScreen
                onClose={handleCloseDrawer}
              >
                <DiscoDrawerHeader
                  testid={`${testid}.header`}
                  title={"Connect Slack"}
                  onClose={handleCloseDrawer}
                  className={classes.drawerHeader}
                />
                <div className={classes.container}>
                  {steps.map((s, index) => (
                    <Fade
                      key={s.id}
                      in={activeStep === index}
                      timeout={700}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div key={s.id} className={classes.item}>
                        <MemberOnboardingSlackContent
                          usageKey={s}
                          mode={"curriculum-item"}
                          successButtonTitle={"Complete Task"}
                          onSuccess={handleCloseDrawer}
                        />
                      </div>
                    </Fade>
                  ))}
                </div>
              </DiscoDrawer>
            )
          }}
        </StepperContext.Consumer>
      </StepperContextProvider>
    </>
  )

  function handleCloseDrawer() {
    contentUsageDrawer.setParams({
      drawerConnectSlackTab: undefined,
    })
  }
}
const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    height: "100%",
    alignItems: "center",
  },
  drawer: {
    padding: 0,
  },
  drawerHeader: {
    padding: theme.spacing(2.5),
    paddingBottom: 0,
  },
  item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}))

export default CurriculumConnectSlackOnboardingDrawer
