import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ProgressIcon from "@/product/course/curriculum/common/ProgressIcon"
import { DiscoTooltip } from "@disco-ui"
import classNames from "classnames"
import React from "react"

interface ContentModuleProgressIconProps {
  totalCompletedItems: number
  totalItems: number
  testid?: string
  className?: string
}

const ContentModuleProgressIcon: React.FC<ContentModuleProgressIconProps> = ({
  totalCompletedItems,
  totalItems,
  testid,
  className,
}) => {
  const progressText = `${totalCompletedItems}/${totalItems} completed`

  const classes = useStyles()

  return (
    <DiscoTooltip data-testid={`${testid}.progress-tooltip`} content={progressText}>
      <div>
        <ProgressIcon
          completion={totalCompletedItems / totalItems}
          className={classNames(classes.iconContainer, className)}
          testid={"CurriculumItemListItemTemplate"}
          variant={"percentage"}
        />
      </div>
    </DiscoTooltip>
  )
}

const useStyles = makeUseStyles({
  iconContainer: {
    "&:hover": {
      cursor: "pointer",
    },
  },
})

export default ContentModuleProgressIcon
