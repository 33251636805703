import { useTrackContentViewFragment$key } from "@/content-usage/drawer/__generated__/useTrackContentViewFragment.graphql"
import { useTrackContentViewMutation } from "@/content-usage/drawer/__generated__/useTrackContentViewMutation.graphql"
import { useEffect } from "react"
import { useFragment, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

// min number of milliseconds before a content view is tracked
const TRACK_CONTENT_VIEW_TIME_MS = 3000

function useTrackContentView(contentUsageKey: useTrackContentViewFragment$key | null) {
  const contentUsage = useFragment<useTrackContentViewFragment$key>(
    graphql`
      fragment useTrackContentViewFragment on ContentUsage {
        id
      }
    `,
    contentUsageKey
  )

  const [commit] = useMutation<useTrackContentViewMutation>(graphql`
    mutation useTrackContentViewMutation($input: TrackContentViewInput!) {
      trackContentView(input: $input) {
        node {
          id
        }
      }
    }
  `)

  useEffect(() => {
    if (!contentUsage) return

    const track = setTimeout(() => {
      commit({
        variables: {
          input: {
            contentUsageId: contentUsage.id,
          },
        },
      })
    }, TRACK_CONTENT_VIEW_TIME_MS)

    return () => clearTimeout(track)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentUsage?.id])
}

export default useTrackContentView
