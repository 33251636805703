import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoMultiSelect, {
  DiscoMultiSelectOption,
} from "@disco-ui/select/DiscoMultiSelect"
import { observer } from "mobx-react-lite"

interface ContentUsagePrerequisiteMultiSelectProps {
  isOpen: boolean
  handleOpen: () => void
  handleClose: () => void
  values: GlobalID[]
  options: DiscoMultiSelectOption[]
  handleChange: (values: string[]) => void
  renderItem: (option: DiscoMultiSelectOption) => JSX.Element | null
}
function ContentUsagePrerequisiteMultiSelect({
  isOpen,
  handleOpen,
  handleClose,
  values,
  options,
  handleChange,
  renderItem,
}: ContentUsagePrerequisiteMultiSelectProps) {
  const classes = useStyles()
  return (
    <DiscoMultiSelect
      testid={"ContentUsagePrerequisiteMultiSelect"}
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      values={values}
      options={options}
      placeholder={"Type to search curriculum modules"}
      onChange={handleChange}
      renderOption={renderItem}
      filterSelectedOptions={false}
      renderTags={() => null}
      disableCloseOnSelect
      classes={{ listbox: classes.listItems }}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  listItems: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
    padding: theme.spacing(1.5),
    "& > li": {
      backgroundColor: "transparent !important",
    },
    "& > li:last-child": {
      justifyContent: "flex-end",
    },
  },
}))

export default observer(ContentUsagePrerequisiteMultiSelect)
