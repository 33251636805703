import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import {
  ContentUsageEntity,
  UpdateContentUsageInput,
} from "@/content-usage/modules/actions/__generated__/ContentModuleForm_UpdateContentUsageMutation.graphql"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoSection,
  DiscoSwitch,
  DiscoText,
} from "@disco-ui"
import DiscoDateTimePickerV1 from "@disco-ui/date/DiscoDateTimePickerV1"
import DateUtils from "@utils/date/dateUtils"
import { isFuture, isPast } from "date-fns"
import { observer } from "mobx-react-lite"
import { useCallback, useMemo } from "react"

interface Props {
  isCreation?: boolean
  parentEntity: ContentUsageEntity
  form: FormStore<UpdateContentUsageInput>
}

function ContentModuleReleaseDateFormField(props: Props) {
  const { form, isCreation, parentEntity } = props

  const contentModuleLabel = ContentModuleUtils.getContentModuleLabel(parentEntity)

  /** Was the module already released? */
  const isReleasedModule = useMemo(() => {
    if (isCreation) return false
    if (!form.initialState.contentUsageInput?.releasedAt) return true
    return isPast(new Date(form.initialState.contentUsageInput.releasedAt))
  }, [isCreation, form.initialState.contentUsageInput?.releasedAt])

  /** Show the toggle as on? */
  const isToggleOn = useMemo(() => {
    // Editting unreleased or creating - toggle directly controls releasedAt
    if (!isReleasedModule) return Boolean(form.state.contentUsageInput?.releasedAt)
    // Editting a released module. Show toggle off by default when
    // form.state.contentUsageInput.releasedAt is in the past. Toggling sets a future date and turns it on.
    if (!form.state.contentUsageInput?.releasedAt) return false
    return isFuture(new Date(form.state.contentUsageInput.releasedAt))
  }, [isReleasedModule, form.state.contentUsageInput?.releasedAt])

  const handleToggle = useCallback(() => {
    if (isToggleOn) {
      form.state.contentUsageInput = {
        ...form.state.contentUsageInput,
        releasedAt: null,
      }
    } else {
      form.state.contentUsageInput = {
        ...form.state.contentUsageInput,
        releasedAt: DateUtils.getOneWeekFromToday(),
      }
    }
  }, [form.state, isToggleOn])

  const labels = useLabels()

  return (
    <>
      <DiscoDivider />
      <DiscoFormControl marginBottom={1} marginTop={1}>
        <DiscoSwitch
          testid={"ContentModuleReleaseDateFormField.toggle"}
          name={"toggle-release-date"}
          checked={isToggleOn}
          onChange={handleToggle}
          label={
            <DiscoText variant={"body-md-600"}>
              {isReleasedModule
                ? `Hide & re-release this ${contentModuleLabel} in the future`
                : `Release this ${contentModuleLabel} in the future`}
            </DiscoText>
          }
        />
      </DiscoFormControl>
      {isToggleOn && (
        <DiscoSection groovyDepths={"insideCard"} marginBottom={2.5}>
          <DiscoFormControl
            label={"Release Time"}
            description={
              isReleasedModule
                ? `This ${contentModuleLabel} has been released. Re-releasing in the future will hide it from ${labels.product_member.plural} and send a notification when it is re-released.`
                : `${labels.product_member.plural} cannot access the ${contentModuleLabel} before this time. ${labels.product_member.plural} will receive a notification when the ${contentModuleLabel} is released.`
            }
            marginBottom={0}
            variant={"two-column"}
          >
            <DiscoDateTimePickerV1
              testid={"ContentModuleReleaseDateFormField.released-at"}
              value={form.state.contentUsageInput?.releasedAt || null}
              onChange={(v) => {
                form.state.contentUsageInput = {
                  ...form.state.contentUsageInput,
                  releasedAt: v?.toISOString() || null,
                }
              }}
              minDate={new Date()}
            />
          </DiscoFormControl>
        </DiscoSection>
      )}
    </>
  )
}

export default observer(ContentModuleReleaseDateFormField)
