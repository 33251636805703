import ContentUsageFormEventsField from "@/content-usage/buttons/events/ContentUsageFormEventsField"
import { ContentModuleFormState } from "@/content-usage/modules/actions/ContentModuleForm"
import { ContentModuleForm_UpdateContentUsageMutation } from "@/content-usage/modules/actions/__generated__/ContentModuleForm_UpdateContentUsageMutation.graphql"
import FormStore from "@/core/form/store/FormStore"
import { DiscoDivider, DiscoFormControl, DiscoSection, DiscoSwitch } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: FormStore<ContentModuleFormState, ContentModuleForm_UpdateContentUsageMutation>
}

function ContentModuleAssociateEventsFormField(props: Props) {
  const { form } = props

  return (
    <>
      <DiscoDivider />
      <DiscoFormControl
        variant={"two-column"}
        label={"Associated Events"}
        description={
          "Drive more registrations and higher attendance by associating events with this Module."
        }
        growLabel
        marginBottom={1.5}
      >
        <DiscoSwitch
          testid={"ContentModuleAssociateEventsFormField.toggle"}
          checked={form.state.isAssociatingEvents}
          onChange={(checked) => (form.state.isAssociatingEvents = checked)}
          hideForm
        />
      </DiscoFormControl>

      {form.state.isAssociatingEvents && (
        <DiscoSection groovyDepths={"insideCard"} marginBottom={2.5}>
          <ContentUsageFormEventsField form={form} />
        </DiscoSection>
      )}
    </>
  )
}

export default observer(ContentModuleAssociateEventsFormField)
