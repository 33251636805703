import { InlineContentDrawerTemplateFragment$data } from "@/content-usage/drawer/__generated__/InlineContentDrawerTemplateFragment.graphql"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import ContentUtils from "@/content/util/contentUtils"
import { GlobalID } from "@/relay/RelayTypes"

type UseInlineContentDrawerContentLabelProps = [
  contentLabelId: GlobalID,
  contentUsage?:
    | (Pick<InlineContentDrawerTemplateFragment$data, "entity"> & {
        content: Pick<
          InlineContentDrawerTemplateFragment$data["content"],
          "type" | "contentLabelId"
        >
      })
    | null
]
export default function useInlineContentDrawerContentLabel(
  ...[contentLabelId, contentUsage]: UseInlineContentDrawerContentLabelProps
) {
  const drawer = useContentUsageDrawer()

  const contentType = drawer.params.drawerContentType || contentUsage?.content.type
  const parentEntity = drawer.params.drawerUsageEntity || contentUsage?.entity

  const contentLabels = ContentUtils.useContentLabels()

  return ContentUtils.useContentLabel({
    content: {
      type: contentType,
      label: contentLabels.find((cl) => cl.id === contentLabelId)?.label || "",
    },
    entity: parentEntity,
  })
}
